import React, { ComponentType } from 'react';
import { Routes, HashRouter, Route } from 'react-router-dom';
import { PrivateRoute, ProtectedRoute, StandardRoutes, UserBar } from '@sdir/auth/lib/components';
import { ProjectDataController } from '@sdir/blueprint.et/lib/components';
import { useIntl } from 'react-intl';
import { createBrowserHistory } from 'history';
import RiskAssessment from '../../Pages/ET/RiskAssessment';
import {
  AppPermissions,
  ShowRequirementListTab,
  ShowTilsynTab
} from '../../../constants/permissions';
import CaseWorkerPage from '../../Pages/ET/CasworkerPage';
import ActivityDetails from '../../Pages/ET/ActivityDetails';
import { Routes as RoutesConstants, VesselRoutes, vesselBasePath } from './constants';
import { Home, Person, NotFoundPage, Certificate, PersonMerge } from '../../Pages';
import MicroFrontend from '../../../MicroFrontend';
import VesselPage from '../../Pages/ET/VesselPage';
import ViewRequirementWrapper from '../../Pages/ET/ViewRequirementWrapper';
import Simulator from '../../Pages/ET/Simulator';
import NewActivity from '../../Pages/ET/NewActivity';
import { ToggleContent } from '../../Atoms';
import ManageCertificates from '../../Pages/ET/ManageCertificates';
import BreadCrumbNavigation from '../../Molecules/BreadCrumbNavigation';
// eslint-disable-next-line max-len
import ConstructionNoticeReviewPage from '../../Pages/ET/ConstructionNotice/ConstructionNoticeReviewPage';
import ConstructionPage from '../../Pages/ET/ConstructionNotice/ConstructionPage';
import APSPagesLayout from '../../Pages/APS/APSPagesLayout';
import UserAdministrationPage from '../../Pages/ET/UserAdministrationPage';
import VesselCardPage from '../../Pages/ET/VesselCardPage';

const defaultHistory = createBrowserHistory();
const {
  REACT_APP_APS_HOST: APSHost,
  REACT_APP_ENDORSEMENT_HOST: EndorsementHost,
  REACT_APP_DIGCERT_HOST: DigCertHost,
  REACT_APP_ACTIVITYADMIN_HOST: ActivityAdminHost
} = process.env;
interface RouterProps {
  Header: ComponentType;
  ContentSection: ComponentType<any>;
}

const APS: React.FC = () => <MicroFrontend host={APSHost} name="APS" history={defaultHistory} />;
const Endorsement: React.FC = () => (
  <MicroFrontend host={EndorsementHost} name="Endorsement" history={defaultHistory} />
);

const DigitalCertificates: React.FC = () => (
  <MicroFrontend host={DigCertHost} name="DigitalCertificates" history={defaultHistory} />
);

const ActivityAdmin: React.FC = () => (
  <MicroFrontend host={ActivityAdminHost} name="ActivityAdministration" history={defaultHistory} />
);

const VesselPageWrapper: React.FC = () => (
  <ToggleContent
    toggleName={ShowRequirementListTab}
    alternativeChildren={
      <ToggleContent toggleName={ShowTilsynTab} alternativeChildren={<VesselPage />} showLoader>
        <VesselPage surveyTabEnabled />
      </ToggleContent>
    }
    showLoader
  >
    <ToggleContent
      toggleName={ShowTilsynTab}
      alternativeChildren={<VesselPage requirementsListTabEnabled />}
      showLoader
    >
      <VesselPage requirementsListTabEnabled surveyTabEnabled />
    </ToggleContent>
  </ToggleContent>
);

const RoutesList: React.FC<RouterProps> = ({ Header, ContentSection }) => {
  const intl = useIntl();
  return (
    <HashRouter>
      <UserBar
        textLogOut={intl.formatMessage({ id: 'userbar.logout' })}
        textOtherUser={intl.formatMessage({ id: 'userbar.otherUser' })}
        hideIdPortenLogo
      />
      <Header />
      <BreadCrumbNavigation />
      <ContentSection>
        <Routes>
          <Route
            path={RoutesConstants.home}
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          <Route element={<APSPagesLayout />}>
            <Route
              path={RoutesConstants.person}
              element={
                <PrivateRoute>
                  <Person />
                </PrivateRoute>
              }
            />
            <Route
              path={RoutesConstants.personMerge}
              element={
                <ProtectedRoute permission={AppPermissions.ViewAccessToMergePersons}>
                  <PersonMerge />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConstants.certificate}
              element={
                <PrivateRoute>
                  <Certificate />
                </PrivateRoute>
              }
            />
            <Route
              path={RoutesConstants.accesscontrol}
              element={
                <PrivateRoute>
                  <UserAdministrationPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path={RoutesConstants.vesselcardpage}
            element={
              <PrivateRoute>
                <VesselCardPage />
              </PrivateRoute>
            }
          />
          <Route
            path={RoutesConstants.vesselSimulator}
            element={
              <PrivateRoute>
                <Simulator />
              </PrivateRoute>
            }
          />
          <Route
            path={RoutesConstants.APS}
            element={
              <PrivateRoute>
                <APS />
              </PrivateRoute>
            }
          >
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <APS />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path={RoutesConstants.Endorsement}
            element={
              <PrivateRoute>
                <Endorsement />
              </PrivateRoute>
            }
          >
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Endorsement />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path={RoutesConstants.ActivityAdmin}
            element={
              <PrivateRoute>
                <ActivityAdmin />
              </PrivateRoute>
            }
          >
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <ActivityAdmin />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path={RoutesConstants.DigitalCertificates}
            element={
              <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                <DigitalCertificates />
              </ProtectedRoute>
            }
          >
            <Route
              path="*"
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <DigitalCertificates />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path={RoutesConstants.FRES}
            element={
              <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                <ToggleContent
                  toggleName={ShowRequirementListTab}
                  alternativeChildren={<CaseWorkerPage />}
                  showLoader
                >
                  <CaseWorkerPage requirementsListTabEnabled />
                </ToggleContent>
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesConstants.construction}
            element={
              <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                <ConstructionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesConstants.constructionUid}
            element={
              <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                <ConstructionPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={RoutesConstants.constructionNoticeReview}
            element={
              <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                <ConstructionNoticeReviewPage />
              </ProtectedRoute>
            }
          />
          <Route path={vesselBasePath}>
            <Route
              path={VesselRoutes.activityDetails}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ActivityDetails />
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.newactivity}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <NewActivity />
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.riskAssessment}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <RiskAssessment />
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.managecertificate}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ProjectDataController>
                    <ManageCertificates />
                  </ProjectDataController>
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.vesselWorkspaceActiveInspections}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ProjectDataController>
                    <VesselPageWrapper />
                  </ProjectDataController>
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.vesselSurveyScope}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ProjectDataController>
                    <VesselPageWrapper />
                  </ProjectDataController>
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.vesselWorkspace}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ProjectDataController>
                    <VesselPageWrapper />
                  </ProjectDataController>
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.vesselWorkspaceScrollTo}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ProjectDataController>
                    <VesselPageWrapper />
                  </ProjectDataController>
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.vesselWorkspaceScrollToChapter}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ProjectDataController>
                    <VesselPageWrapper />
                  </ProjectDataController>
                </ProtectedRoute>
              }
            />
            <Route
              path={VesselRoutes.requirementDetail}
              element={
                <ProtectedRoute permission={AppPermissions.ReadAccessToProjects}>
                  <ViewRequirementWrapper />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="*" element={<StandardRoutes notFoundComponent={<NotFoundPage />} />} />
        </Routes>
      </ContentSection>
    </HashRouter>
  );
};

export default RoutesList;

// eslint-disable-next-line max-len
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ProjectInspectionsPage, ProjectSurveyPage } from '@sdir/blueprint.et/lib/components';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { useIntl } from 'react-intl';
import { useToast } from '@sdir/utilities/lib/hooks';
import useAccess from './Access/useAccess';
import { NewInspectionFlow } from '../../constants/permissions';
import { ToggleContent } from '../Atoms';
import { getVesselCertificatesApi } from '../../httpclient';
import useETCaseworker from '../../services/hooks/useETCaseworker';

interface Props {
  vesselUid: string | null | undefined;
}

const InspectionSection: React.FC<Props> = ({ vesselUid }) => {
  const { projectuid } = useParams() as { projectuid: string };
  const intl = useIntl();
  const { setError } = useToast();

  const { getEtCaseWorkers } = useETCaseworker();

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);
  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);

  const { teamMembers } = useAccess(projectuid, vesselUid);

  const { callApi: getVesselCertificates, result: vesselCertificates } = useApi({
    apiFunction: () =>
      getVesselCertificatesApi.v1GetvesselcertificatesGetvesselcertificatesVesselUidGet(
        vesselUid ?? ''
      ),
    errorCallback: err => {
      err.length > 0 &&
        setError(`${intl.formatMessage({ id: 'survey.certificates.toast.error' })}: ${err}`);
    }
  });

  useEffect(() => {
    if (!etCaseWorkers && !etCaseWorkersLoading) {
      getEtCaseWorkers();
    }

    vesselUid && getVesselCertificates();
  }, [vesselUid]);

  return (
    <ToggleContent
      toggleName={NewInspectionFlow}
      alternativeChildren={
        <ProjectInspectionsPage
          isCaseWorker
          etCaseWorkers={etCaseWorkers}
          teamMembers={teamMembers?.users ?? []}
          projectuid={projectuid}
        />
      }
      showLoader
    >
      <ProjectSurveyPage
        isCaseWorker
        projectuid={projectuid}
        etCaseWorkers={etCaseWorkers}
        teamMembers={teamMembers?.users ?? []}
        vesselCertificates={vesselCertificates ?? []}
      />
    </ToggleContent>
  );
};

export default InspectionSection;

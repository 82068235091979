import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import '../../../assets/fonts/font.css';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { Loader } from '@sdir/sds';
import { Certificate as CertificateCard } from '@sdir/blueprint.aps/lib/components';
import { CertificateProps } from '@sdir/blueprint.aps/lib/components/Organisms/Certificate';
import { formatFullName } from '@sdir/blueprint.aps/lib/helpers/formattingHelpers';
import { personalCertificateApi } from '../../../httpclient';
import { CapitalizeString } from '../../../helpers';

const Seafarer: React.FC = () => {
  const intl = useIntl();

  const { commonAddresseeId, projectuid: uid } = useParams() as {
    commonAddresseeId: string;
    projectuid: string;
  };

  const { result: resultCertificate, loading, callApi: getCertificate } = useApi({
    apiFunction: UID =>
      personalCertificateApi?.v2PersonalcertificateGetpersonalcertificatev2UidGet(UID),
    bind: personalCertificateApi,
    callApiOnInit: false
  });

  const qrData = useMemo(() => {
    if (process?.env?.REACT_APP_VERIFY_URL) {
      return `${process?.env?.REACT_APP_VERIFY_URL}/${uid}`;
    }
    return '';
  }, [uid]);

  useEffect(() => {
    getCertificate(uid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonAddresseeId, uid]);

  const certificateCard = useMemo((): CertificateProps | any => {
    const result = resultCertificate;
    if (!result) return null;
    const positionList =
      result?.limitations?.map(l => {
        return {
          position: l.capacity || '',
          limitations: l.limit ? [CapitalizeString(l.limit)] : []
        };
      }) || [];
    const hideParagraph = !!(
      !result?.certificateCode ||
      ['D5L', 'D5LA', 'HHBF', 'HHBFV', 'HHBVS', 'FSC0'].includes(result.certificateCode)
    );
    const fullName: string =
      formatFullName(result?.firstName || '', result?.middleName || '', result?.lastName || '') ||
      '';
    return {
      name: fullName,
      dateOfBirth: result?.dateOfBirth || '',
      certificateNr:
        !result?.certificateNumber || result?.certificateNumber === '0'
          ? '-'
          : result?.certificateNumber,
      certificateName: CapitalizeString(result?.certificateName || ''),
      issuedDate: result?.issuedDate,
      validFrom: result?.validFromDate,
      validDate: result?.expiryDate || '',
      positionList,
      hideLanguageSelector: true,
      hideExtraParagraph: hideParagraph,
      minimumRequirements: result?.minimumRequirements || '',
      stcwReference: result?.stcwReference || '',
      qrData,
      qrImageLoading: false
    };
  }, [resultCertificate, qrData]);

  return (
    <div>
      <SeafarerContainer>
        <Title>{intl.formatMessage({ id: 'aps.certificate.title' })}</Title>
        <Wrapper>{certificateCard && <CertificateCard {...certificateCard} />}</Wrapper>
        {loading && <Loader />}
      </SeafarerContainer>
    </div>
  );
};

export default Seafarer;

const Wrapper = styled.div`
  width: 600px;

  p {
    margin: 0;
  }
`;

const SeafarerContainer = styled.section`
  width: 100%;
  margin: 0 auto 0 auto;
  display: grid;
  padding: 1.5rem;
`;

const Title = styled.div`
  font-size: 3rem;
  font-weight: 700;
  margin: 5px;
  color: ${({ theme }) => theme.colors.font.PBM};
`;

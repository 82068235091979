interface RoutesInterface {
  home: string;
  person: string;
  personMerge: string;
  certificate: string;
  accesscontrol: string;
  vesselSimulator: string;
  FRES: string;
  activityDetails: string;
  newactivity: string;
  riskAssessment: string;
  managecertificate: string;
  requirementDetail: string;
  vesselWorkspace: string;
  vesselWorkspaceActiveInspections: string;
  vesselSurveyScope: string;
  vesselWorkspaceScrollTo: string;
  vesselWorkspaceScrollToChapter: string;
  APS: string;
  application: string;
  Endorsement: string;
  DigitalCertificates: string;
  ActivityAdmin: string;
  construction: string;
  constructionUid: string;
  constructionNoticeReview: string;
  vesselcardpage: string;
}

interface VesselRoutesInterface {
  activityDetails: string;
  newactivity: string;
  riskAssessment: string;
  managecertificate: string;
  requirementDetail: string;
  vesselWorkspace: string;
  vesselWorkspaceActiveInspections: string;
  vesselSurveyScope: string;
  vesselWorkspaceScrollTo: string;
  vesselWorkspaceScrollToChapter: string;
  vesselcardpage: string;
}

export const VesselRoutes: VesselRoutesInterface = {
  activityDetails: ':projectuid/activitydetails/:type/:activityuid',
  newactivity: ':projectuid/newactivity/',
  riskAssessment: ':projectuid/riskassessment',
  managecertificate: ':projectuid/managecertificate/',
  requirementDetail: ':projectuid/requirementdetail/:activityuid',
  vesselWorkspace: ':projectuid/:tab',
  vesselWorkspaceActiveInspections: ':projectuid/:tab/:inspectiontab',
  vesselSurveyScope: ':projectuid/:tab/scope/:surveyuid',
  vesselWorkspaceScrollTo: ':projectuid/:tab/:scrollToId',
  vesselWorkspaceScrollToChapter: ':projectuid/:tab/:scrollToId/:chapterIdx',
  vesselcardpage: 'card/:vesselUid/:tab'
};

export const vesselBasePath = '/et/vessel';

export const vesselTabOptions = {
  requirementlist: 'requirementlist',
  vesselcard: 'vesselcard',
  activitylist: 'activitylist',
  projectmanagement: 'projectmanagement',
  inspection: 'inspection',
  incident: 'incident',
  certificate: 'certificate'
};

export const vesselTabOptionsHiddenRequirementList = {
  vesselcard: 'vesselcard',
  activitylist: 'activitylist',
  projectmanagement: 'projectmanagement',
  inspection: 'inspection',
  incident: 'incident',
  certificate: 'certificate'
};

export const Routes: RoutesInterface = {
  home: '/',
  person: '/person/:commonAddresseeId',
  personMerge: '/person/:commonAddresseeId/merge',
  certificate: '/certificate/:commonAddresseeId/:projectuid',
  accesscontrol: '/et/accesscontrol/:organizationNumber/',
  vesselSimulator: '/vesselsimulator',
  FRES: '/et',
  activityDetails: `${vesselBasePath}/${VesselRoutes.activityDetails}`,
  newactivity: `${vesselBasePath}/${VesselRoutes.newactivity}`,
  riskAssessment: `${vesselBasePath}/${VesselRoutes.riskAssessment}`,
  managecertificate: `${vesselBasePath}/${VesselRoutes.managecertificate}`,
  requirementDetail: `${vesselBasePath}/${VesselRoutes.requirementDetail}`,
  vesselWorkspace: `${vesselBasePath}/${VesselRoutes.vesselWorkspace}`,
  vesselWorkspaceActiveInspections: `${vesselBasePath}/${VesselRoutes.vesselWorkspaceActiveInspections}`,
  vesselSurveyScope: `${vesselBasePath}/${VesselRoutes.vesselSurveyScope}`,
  vesselWorkspaceScrollTo: `${vesselBasePath}/${VesselRoutes.vesselWorkspaceScrollTo}`,
  vesselWorkspaceScrollToChapter: `${vesselBasePath}/${VesselRoutes.vesselWorkspaceScrollToChapter}`,
  vesselcardpage: `${vesselBasePath}/${VesselRoutes.vesselcardpage}`,
  construction: `/et/construction/:currentStep/`,
  constructionUid: `/et/construction/:currentStep/:constructionnoticeid`,
  constructionNoticeReview: `/et/constructionnoticereview/:constructionnoticeid`,
  APS: '/aps',
  application: '/aps/applications/:applicationUid',
  Endorsement: '/endorsement',
  DigitalCertificates: '/digitalcertificates',
  ActivityAdmin: '/activityadministration'
};

import React from 'react';
import { PartDto, SubsectionDto } from '@sdir/httpclient/lib/clients/et/regulationtext';
import { SdiArrow, styles } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { Button, ChevronWrapper, ListItem, TitleWrapper, scrollTo } from './RegulationsList';
import { Chapter } from './Chapter';

export interface IPart {
  part: PartDto;
  selectedPart: number | undefined;
  selectedSection: number | undefined;
  partIndex: number;
  handlePartClick: (index: number | undefined) => void;
  selectedChapter: number | undefined;
  handleChapterClick: (index: number | undefined) => void;
  handleSectionClick: (index: number | undefined) => void;
  handleSubsectionClick: (subsection: SubsectionDto) => void;
}
export const Part: React.FC<IPart> = ({
  part,
  selectedPart,
  partIndex,
  handlePartClick,
  selectedChapter,
  selectedSection,
  handleChapterClick,
  handleSectionClick,
  handleSubsectionClick
}) => {
  const active = selectedPart === partIndex;
  const intl = useIntl();

  const onPartClick = () => {
    handlePartClick(partIndex);
    setTimeout(() => {
      scrollTo(part.uid);
    }, 200);
  };

  return (
    <ListItem paddingRight="0" id={part.uid}>
      <Button type="button" active={active} onClick={onPartClick}>
        <TitleWrapper>
          {`${intl.formatMessage({
            id: 'regulationsselector.breadcrumb.part'
          })} ${part.index}. ${part.title}`}
          <ChevronWrapper>
            <SdiArrow
              direction={active ? 'up' : 'down'}
              height="1rem"
              size="s"
              color={active ? styles.colors.font.white : styles.colors.primary2}
            />
          </ChevronWrapper>
        </TitleWrapper>
      </Button>
      {active && (
        <ul>
          {part.chapters?.map((chapter, chapterIndex) => (
            <Chapter
              handleSubsectionClick={handleSubsectionClick}
              key={chapter.uid}
              chapter={chapter}
              chapterIndex={chapterIndex}
              selectedChapter={selectedChapter}
              handleChapterClick={handleChapterClick}
              handleSectionClick={handleSectionClick}
              selectedSection={selectedSection}
            />
          ))}
        </ul>
      )}
    </ListItem>
  );
};

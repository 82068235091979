import React from 'react';
import { SectionDto, SubsectionDto } from '@sdir/httpclient/lib/clients/et/regulationtext';
import { SdiArrow, styles } from '@sdir/sds';
import { Button, ChevronWrapper, ListItem, TitleWrapper, scrollTo } from './RegulationsList';

export interface ISection {
  sectionIndex: number;
  selectedSection: number | undefined;
  handleSectionClick: (index: number | undefined) => void;
  section: SectionDto;
  handleSubsectionClick: (subsection: SubsectionDto) => void;
}
export const Section: React.FC<ISection> = ({
  sectionIndex,
  selectedSection,
  handleSectionClick,
  section,
  handleSubsectionClick
}) => {
  const active = sectionIndex === selectedSection;

  const onSectionClick = () => {
    handleSectionClick(sectionIndex);
    setTimeout(() => {
      scrollTo(section.uid);
    }, 200);
  };

  return (
    <ListItem paddingRight="0" id={section.uid}>
      <Button type="button" active={active} onClick={onSectionClick}>
        <TitleWrapper>
          {`§ ${section.index}. ${section.title}`}
          <ChevronWrapper>
            <SdiArrow
              direction={active ? 'up' : 'down'}
              height="1rem"
              size="s"
              color={active ? styles.colors.font.white : styles.colors.primary2}
            />
          </ChevronWrapper>
        </TitleWrapper>
      </Button>
      {active && (
        <ul>
          {section.subsections?.map(subsection => (
            <ListItem paddingRight="0" key={subsection.uid} id={subsection.uid}>
              <Button onClick={() => handleSubsectionClick(subsection)} type="button">
                {`(${subsection.index}) ${subsection.content}`}
              </Button>
            </ListItem>
          ))}
        </ul>
      )}
    </ListItem>
  );
};

import styled from 'styled-components';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, generatePath } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Loader, styles, SdirButton, SdiClose, DropdownModal } from '@sdir/sds';
import {
  SurveyScopeSubjectAreasWithActivities,
  SurveyScopeActions,
  getSubjectAreaDocumentsAndActivities,
  getActivitiesBySubjectArea,
  ListMenuV2,
  TaskFiltersV1,
  useScopeFilterSummary,
  useDynamicHeight,
  SurveyScopeCertificateCard,
  SurveyCertificateData
} from '@sdir/blueprint.et/lib/components';
import { TaskStatusFilterOptions } from '@sdir/blueprint.et/lib/types/enums';
import {
  ActivityWithRisk,
  SetActivityNotRelevantCommand
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { SubjectAreaOptionDto } from '@sdir/httpclient/lib/clients/core/requirement';
import {
  SuggestedScopeActivity,
  UpdateScopeDraftActivity
} from '@sdir/httpclient/lib/clients/et/survey';
import { ActiveFilters } from '@sdir/blueprint.et/lib/components/Organisms/TaskFilters/TaskFiltersV1';
import { ToggleState } from './ActivityCard/ActivityCardHelpers';
import { ActivitiesListChanged } from '../../store/constants/types';
// eslint-disable-next-line max-len
import useSurveyScope from '../../services/hooks/useSurveyScope';
import { Routes, vesselTabOptions } from '../Templates/Routes/constants';
import useScopeFilter from '../../services/hooks/useScopeFilter';
import { SurveyScopeActivityList } from '../Organisms';
import { ReactComponent as NoActivitySelected } from '../../assets/img/NoActivitySelected.svg';
import NotRelevantCard from '../Organisms/NotRelevantCard';

export type ScopedActivity = ActivityWithRisk &
  UpdateScopeDraftActivity & {
    isPartOfOtherSurveyScope?: boolean;
    isIncludedInCurrentSurvey?: boolean;
  };
interface SurveyScopeSectionProps {
  projectUid: string;
  surveyUid: string;
}

const SurveyScopeSection: React.FC<SurveyScopeSectionProps> = ({ projectUid, surveyUid }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [selectedSubjectareaUri, setSelectedSubjectareaUri] = useState<string | null>(null);
  const [toggleState, setToggleState] = useState<ToggleState>(ToggleState.None);
  const subjectAreaList: SubjectAreaOptionDto[] = useSelector(
    (state: any) => state.appState.subjectAreas
  );
  const scopeActivitiesChanged: ActivitiesListChanged = useSelector(
    (state: any) => state.surveyState.scopeActivitiesListChanged
  );
  const selectedSurvey: SurveyCertificateData = useSelector(
    (state: any) => state.surveyState.selectedSurveyCertificateData
  );
  const activities: SuggestedScopeActivity[] | undefined = useSelector(
    (state: any) => state.surveyState.scopeActivitiesList
  );

  // Load activities for suggested survey scope
  const {
    subjectAreaListLoading,
    getSubjectAreas,
    activitiesLoading,
    getActivities,
    setSurveyScope,
    setRelevance,
    updateSurveyScope
  } = useSurveyScope(projectUid, surveyUid);

  useEffect(() => {
    if (selectedSurvey.surveyId && selectedSurvey.surveyId !== '') {
      getSubjectAreas();
      getActivities();
    } else {
      navigate(
        generatePath(Routes.vesselWorkspaceActiveInspections, {
          projectuid: projectUid,
          tab: vesselTabOptions.inspection,
          inspectiontab: 'active'
        })
      );
    }
  }, [selectedSurvey]);

  // Filter Activities in Suggested Survey Scope
  const { ref, height } = useDynamicHeight([activitiesLoading] as any);
  const {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    refreshFiltersAndSelectedActivities,
    availableDropdownValues
  } = useScopeFilter();

  const subjectAreasWithTasks: SurveyScopeSubjectAreasWithActivities[] = useMemo(() => {
    return getSubjectAreaDocumentsAndActivities(subjectAreaList, filteredActivities);
  }, [subjectAreaList, filteredActivities]);

  const onMenuItemClick = async (
    subjectAreaUri: string | null = null,
    activitiesChanged?: SuggestedScopeActivity[],
    action?: SurveyScopeActions
  ) => {
    if (action !== undefined && activitiesChanged !== undefined)
      await setSurveyScope(activitiesChanged, action);
    if (subjectAreaUri) setSelectedSubjectareaUri(subjectAreaUri);
  };

  useEffect(() => {
    // if no subject area is selected/clicked, first subject area will be selected by default
    if (
      (selectedSubjectareaUri === null ||
        !subjectAreasWithTasks?.find(x => x.subjectAreaValue === selectedSubjectareaUri)) &&
      subjectAreasWithTasks?.[0]?.subjectAreaValue
    ) {
      setSelectedSubjectareaUri(subjectAreasWithTasks[0].subjectAreaValue);
    }
  }, [subjectAreasWithTasks]);

  const selectedSubjectAreaTasks = useMemo(() => {
    if (!selectedSubjectareaUri) return [];
    const listOfActivities = getActivitiesBySubjectArea(
      selectedSubjectareaUri,
      subjectAreasWithTasks
    );
    return listOfActivities.map(activity => {
      return {
        uid: activity.approvedActivityId,
        approvedActivityId: activity.approvedActivityId,
        name: activity.title,
        description: activity.description,
        checkpointUri: activity?.checkpointType?.value,
        checkpointType: activity?.checkpointType?.value,
        subjectAreaUri: activity?.checkpointType?.label,
        certificates: activity?.certificates?.map(certificate => certificate.value),
        isPartOfOtherSurveyScope: activity.isPartOfOtherSurveyScope,
        internalComment: activity.internalComment,
        // following fields can be changed in redux state, so check and use that if available
        isMandatory:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isMandatory ??
          activity.isMandatory,
        isCaseHandledInCurrentSurvey:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isCaseHandeledInCurrentSurvey ??
          activity.isCaseHandeledInCurrentSurvey,
        // responsible: activity.responsible,
        isIncludedInCurrentSurvey:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isIncludedInCurrentSurvey ??
          activity.isIncludedInCurrentSurvey,
        isNotRelevant:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.isNotRelevant ??
          activity.isNotRelevant,
        notRelevantReason:
          scopeActivitiesChanged?.[activity.approvedActivityId!]?.notRelevantReason ?? ''
      };
    }) as ScopedActivity[];
  }, [selectedSubjectareaUri, subjectAreasWithTasks, scopeActivitiesChanged, activeFilters]);

  const statusAndTypeTotals = useScopeFilterSummary(activities);
  if (activitiesLoading || subjectAreaListLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );

  return (
    <>
      <SectionContainer>
        <Button
          onClick={() =>
            navigate(
              generatePath(Routes.vesselWorkspace, {
                projectuid: projectUid,
                tab: vesselTabOptions.inspection
              })
            )
          }
        >
          {intl.formatMessage({ id: 'common.scope.button.cancel' })}
          <SdiClose size="s" color={styles.colors.primary} />
        </Button>
        <Button onClick={() => updateSurveyScope()}>
          {intl.formatMessage({ id: 'survey.card.edit.scope' })}
        </Button>
      </SectionContainer>
      <SurveyScopeCertificateCard />
      {subjectAreaList && filteredActivities && (
        <>
          <ListContainer>
            <LeftContainer>
              <TaskFiltersV1
                onFiltersChanged={(newActiveFilters: ActiveFilters) =>
                  onFiltersChanged(newActiveFilters)
                }
                activeFilters={activeFilters}
                statusAndTypeTotals={{ ...statusAndTypeTotals }}
                refreshFiltersAndSelectedActivities={(selectedFilters: ActiveFilters) =>
                  refreshFiltersAndSelectedActivities(selectedFilters)
                }
                availableFilterDropdownTypes={availableDropdownValues}
                filterButtonLabel={intl.formatMessage({
                  id: 'common.filter.scope.button.label'
                })}
                statusTypes={[
                  TaskStatusFilterOptions.NotRelevant,
                  TaskStatusFilterOptions.Included,
                  TaskStatusFilterOptions.Excluded
                ]}
              />
              <div ref={ref}>
                <ListMenuV2
                  title={intl.formatMessage({
                    id: 'common.filter.scope.label'
                  })}
                  onMenuItemClick={(
                    subjectAreaUri: string | null,
                    actvities?: SuggestedScopeActivity[],
                    action?: SurveyScopeActions
                  ) => onMenuItemClick(subjectAreaUri, actvities, action)}
                  subjectAreasWithTasks={subjectAreasWithTasks}
                  activeFilters={activeFilters}
                  availableDropdownValues={availableDropdownValues}
                  onFiltersChanged={(newFilters: ActiveFilters) => onFiltersChanged(newFilters)}
                />
              </div>
            </LeftContainer>
            {selectedSubjectAreaTasks.length > 0 && (
              <ActivitiesContainer>
                <SectionContainer>
                  <HeadingContainer>
                    {intl.formatMessage({
                      id: 'common.filter.scope.label'
                    })}
                    {` (${selectedSubjectAreaTasks.length})`}
                  </HeadingContainer>
                  <Button onClick={() => setRelevance(selectedSubjectAreaTasks, false)}>
                    {intl.formatMessage({ id: 'survey.section.setallrelevant.button.label' })}
                  </Button>
                  <DropdownModalContainer>
                    <Button
                      onClick={() =>
                        setToggleState(
                          toggleState === ToggleState.NotRelevant
                            ? ToggleState.Relevant
                            : ToggleState.NotRelevant
                        )
                      }
                    >
                      {intl.formatMessage({
                        id: 'survey.section.setallnotrelevant.button.label'
                      })}
                    </Button>
                    {toggleState === ToggleState.NotRelevant && (
                      <DropdownModal isOpened>
                        <NotRelevantCard
                          toggleState={ToggleState.NotRelevant}
                          submitNotRelevant={(payload: SetActivityNotRelevantCommand) =>
                            setRelevance(selectedSubjectAreaTasks, true, payload.reason)
                          }
                          submitRelevant={() => setRelevance(selectedSubjectAreaTasks, false)}
                          onAction={() => {
                            setToggleState(ToggleState.None);
                          }}
                          onCancel={() => {
                            setToggleState(ToggleState.None);
                          }}
                          buttonLabel={intl.formatMessage({ id: 'notrelevantcard.button.set' })}
                        />
                      </DropdownModal>
                    )}
                  </DropdownModalContainer>
                </SectionContainer>
                <SurveyScopeActivityList
                  height={height}
                  loading={activitiesLoading}
                  currentSelectedSubjectArea={selectedSubjectareaUri}
                  activitiesListOfSubjectArea={selectedSubjectAreaTasks}
                  setSurveyScope={(
                    newScopeActivitiesChanged: UpdateScopeDraftActivity[],
                    action?: SurveyScopeActions
                  ) => setSurveyScope(newScopeActivitiesChanged, action)}
                  setRelevance={(
                    scopedActivities: Array<ScopedActivity>,
                    isNotRelevant: any,
                    relevanceComment?: any
                  ) => setRelevance(scopedActivities, isNotRelevant, relevanceComment)}
                />
              </ActivitiesContainer>
            )}
            {!activitiesLoading && (!filteredActivities || filteredActivities.length === 0) && (
              <NoActivityContainer>
                <NoActivitySelected />
                <NoActivitySelectedHeader>
                  {intl.formatMessage({ id: 'activityadmin.no.activity.selected' })}
                </NoActivitySelectedHeader>
                <NoActivitySelectedText>
                  {intl.formatMessage({ id: 'activityadmin.msg.select.activity' })}
                </NoActivitySelectedText>
              </NoActivityContainer>
            )}
          </ListContainer>
        </>
      )}
    </>
  );
};

export default SurveyScopeSection;

const LeftContainer = styled.div`
  width: 70rem;
  box-shadow: 0 0.375rem 0.75rem 0 rgb(0 0 0 / 29%);
  background: ${({ theme }) => theme.colors.font.white};
  padding: 4rem 4rem 4rem 2rem;
`;

const ActivitiesContainer = styled.div`
  margin-left: 5rem;
  display: block;
  float: left;
`;

const SectionContainer = styled.div`
  display: flex;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const HeadingContainer = styled.div`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
  display: flex;
  float: left;
  margin-right: auto;
`;

const DropdownModalContainer = styled.div`
  display: contents;
`;

const ListContainer = styled.div`
  display: flex;
  margin-top: 2rem;
`;

const LoaderContainer = styled.div`
  grid-area: 3 / 7;
`;

const NoActivityContainer = styled.div`
  grid-area: tasks;
  text-align: center;
  flex-grow: 1;
`;

const NoActivitySelectedText = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fonts.stfLight};
`;

const NoActivitySelectedHeader = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

const Button = styled(SdirButton)`
  margin-right: 2rem;
  display: flex;
  float: left;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  gap: 1rem;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
`;

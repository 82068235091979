import styled from 'styled-components';
import React, { useState, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Loader, SdirToggle } from '@sdir/sds';
// eslint-disable-next-line max-len
import {
  ActivityStatus,
  GetProjectActivitiesV3Result
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import {
  useTaskFilter,
  TaskFiltersV1,
  useTaskFilterSummary,
  useDynamicHeight,
  ActivitiesSubjectAreasWithActivities,
  getActivityListSubjectAreaDocumentsAndActivities
} from '@sdir/blueprint.et/lib/components';
import { useParams } from 'react-router-dom';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import ListmenuActivities from '@sdir/blueprint.et/lib/components/Organisms/ActivitiesOverviewMenu/ListMenuActivities';
import { SubjectAreaOptionDto } from '@sdir/httpclient/lib/clients/core/requirement';
import RequirementsMeatballMenuV1 from './RequirementsMeatballMenuV1';
import { ActivityList } from '../Organisms';
import { ReactComponent as NoActivitySelected } from '../../assets/img/NoActivitySelected.svg';
import { projectApiConstruction } from '../../httpclient';

interface Props {
  menuListLoading: boolean;
  activityList: GetProjectActivitiesV3Result | undefined;
  activitiesLoading: boolean;
  getActivityList: () => void;
  currentSelectedSubjectarea?: string;
  requirementsListTabEnabled?: boolean;
  subjectAreas: SubjectAreaOptionDto[];
  handleSelectedSubjectArea: (subjectAreaUri?: string) => void;
  setIncludeNotRelevantActivities: React.Dispatch<React.SetStateAction<boolean>>;
  includeNotRelevantActivities: boolean;
}

const ActivitySection: React.FC<Props> = ({
  subjectAreas,
  menuListLoading,
  activitiesLoading,
  activityList,
  getActivityList,
  requirementsListTabEnabled,
  handleSelectedSubjectArea,
  currentSelectedSubjectarea,
  setIncludeNotRelevantActivities,
  includeNotRelevantActivities
}) => {
  const intl = useIntl();
  const { ref, height } = useDynamicHeight([activitiesLoading] as any);
  const { projectuid } = useParams() as { projectuid: string };

  const [activityListCopy, setActivityListCopy] = useState(activityList);

  const activitiesFilteredOnNotRelevant = useMemo(() => {
    if (!includeNotRelevantActivities && activityListCopy?.activities) {
      return activityListCopy.activities.filter(
        act => act.lastEvent?.status !== ActivityStatus.NotRelevant
      );
    }

    return activityListCopy?.activities;
  }, [includeNotRelevantActivities, activityListCopy?.activities]);

  useEffect(() => setActivityListCopy(activityList), [activityList]);

  const replaceActivitiesAndDocumentsInListWithFetchedData = (
    res: GetProjectActivitiesV3Result
  ) => {
    let documents = activityList?.documents ?? [];
    let activities = activityList?.activities ?? [];
    const fetchedDocuments = res.documents ?? [];
    const fetchedActivities = res.activities ?? [];

    activities = activities.map(existingActivity => {
      // Find a matching item in the subsetList by id
      const updatedItem = fetchedActivities.find(
        fetchedActivity => fetchedActivity.uid === existingActivity.uid
      );

      // Replace if found, otherwise keep the existing item
      return updatedItem ?? existingActivity;
    });

    documents = documents.map(existingDocument => {
      // Find a matching item in the subsetList by id
      const updatedItem = fetchedDocuments.find(
        fetchedDocument => fetchedDocument.uri === existingDocument.uri
      );

      // Replace if found, otherwise keep the existing item
      return updatedItem ?? existingDocument;
    });

    setActivityListCopy({ activities, documents });
  };

  const { callApi: fetchAndUpdateSubjectAreaActivityCardData } = useApi({
    apiFunction: (subjectAreaUri: string | undefined) =>
      projectApiConstruction.v3ProjectGetprojectactivitiesSubjectAreaUriGet(
        projectuid,
        subjectAreaUri
      ),
    successCallback: res => {
      replaceActivitiesAndDocumentsInListWithFetchedData(res);
    }
  });

  const {
    filteredActivities,
    activeFilters,
    onFiltersChanged,
    refreshFiltersAndSelectedActivities,
    resetStatusAndCertificateFilters,
    availableDropdownValues
  } = useTaskFilter(
    activitiesFilteredOnNotRelevant,
    includeNotRelevantActivities,
    setIncludeNotRelevantActivities,
    getActivityList
  );

  const statusAndTypeTotals = useTaskFilterSummary(activityListCopy?.activities);

  const subjectAreasWithTasks: ActivitiesSubjectAreasWithActivities[] = useMemo(() => {
    return getActivityListSubjectAreaDocumentsAndActivities(
      subjectAreas ?? [],
      filteredActivities ?? []
    );
  }, [subjectAreas, filteredActivities]);

  useEffect(() => {
    if (!currentSelectedSubjectarea && (subjectAreasWithTasks ?? []).length) {
      handleSelectedSubjectArea(subjectAreasWithTasks[0].subjectAreaValue!);
    }
  }, [subjectAreasWithTasks]);

  if (menuListLoading)
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  return (
    <>
      <FlexWrapper>
        <DisplayNotRelevantToggleWrapper>
          <span>{intl.formatMessage({ id: 'taskfilterbutton.displaynotrelevant' })}</span>
          <SdirToggle
            checked={includeNotRelevantActivities}
            onClick={() => setIncludeNotRelevantActivities(!includeNotRelevantActivities)}
          />
        </DisplayNotRelevantToggleWrapper>
        <RequirementsMeatballMenuV1 />
      </FlexWrapper>
      {subjectAreas && (
        <>
          <ListContainer>
            <ListMenuContainer>
              <TaskFiltersV1
                onFiltersChanged={onFiltersChanged}
                activeFilters={activeFilters}
                statusAndTypeTotals={{ ...statusAndTypeTotals }}
                refreshFiltersAndSelectedActivities={refreshFiltersAndSelectedActivities}
                availableFilterDropdownTypes={availableDropdownValues}
              />
              <div ref={ref}>
                <ListmenuActivities
                  activeFilters={activeFilters}
                  availableDropdownValues={availableDropdownValues}
                  subjectAreasWithTasks={subjectAreasWithTasks}
                  totalNumberOfActivites={activitiesFilteredOnNotRelevant?.length ?? 0}
                  totalNumberOfFilteredActivites={filteredActivities?.length ?? 0}
                  onFiltersChanged={onFiltersChanged}
                  handleSelectedSubjectArea={handleSelectedSubjectArea}
                  selectedSubjectAreaUri={currentSelectedSubjectarea}
                />
              </div>
            </ListMenuContainer>
            {((filteredActivities && filteredActivities.length > 0) || activitiesLoading) && (
              <ActivityList
                height={height}
                loading={activitiesLoading}
                currentSelectedSubjectarea={currentSelectedSubjectarea}
                requirementsListTabEnabled={requirementsListTabEnabled}
                removeFilterCallback={resetStatusAndCertificateFilters}
                subjectAreasWithTasks={subjectAreasWithTasks}
                activityList={activityListCopy}
                fetchAndUpdateSubjectAreaActivityCardData={
                  fetchAndUpdateSubjectAreaActivityCardData
                }
              />
            )}
            {!activitiesLoading && (!filteredActivities || filteredActivities.length === 0) && (
              <NoActivityContainer>
                <NoActivitySelected />
                <NoActivitySelectedHeader>
                  {intl.formatMessage({ id: 'activityadmin.no.activity.selected' })}
                </NoActivitySelectedHeader>
                <NoActivitySelectedText>
                  {intl.formatMessage({ id: 'activityadmin.msg.select.activity' })}
                </NoActivitySelectedText>
              </NoActivityContainer>
            )}
          </ListContainer>
        </>
      )}
    </>
  );
};

export default ActivitySection;

const ListMenuContainer = styled.div`
  height: fit-content;
  box-shadow: 0 0.375rem 0.75rem 0 rgb(0 0 0 / 29%);
  background: ${({ theme }) => theme.colors.font.white};
  padding: 4rem 4rem 4rem 2rem;
`;

const DisplayNotRelevantToggleWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
`;

const ListContainer = styled.div`
  display: flex;
`;

const LoaderContainer = styled.div`
  grid-area: 3 / 7;
`;

const NoActivityContainer = styled.div`
  grid-area: tasks;
  text-align: center;
  flex-grow: 1;
`;

const NoActivitySelectedText = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  font-family: ${({ theme }) => theme.fonts.stfLight};
`;

const NoActivitySelectedHeader = styled.div`
  display flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfBook};
`;

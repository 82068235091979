import React from 'react';
import { FileUpload, FileLink } from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { GetAttachmentResult } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { allowedUploadFileTypes } from '../../../helpers';

interface Props {
  uploadedFiles: GetAttachmentResult[];
  unuploadedFiles: File[];
  uploadFiles: (files: File[]) => void;
  isUploading: boolean;
  progress: number;
  deleteFile: (uploaded: boolean, key?: string | null) => void;
  reactionUid?: string;
  downloadAttachment: (reactionUid, attachmentUid) => void;
}

const ReactionFileSection: React.FC<Props> = ({
  uploadedFiles,
  unuploadedFiles,
  uploadFiles,
  isUploading,
  progress,
  deleteFile,
  reactionUid,
  downloadAttachment
}) => {
  const intl = useIntl();

  const getFileNameFromBlobUrl = url => {
    if (reactionUid) {
      return url.split(`${reactionUid}/`)[1].split('?')[0];
    }
    return '?';
  };

  return (
    <>
      <FileUpload
        width="32rem"
        multiple
        allowedTypes={allowedUploadFileTypes}
        buttonLabel={intl.formatMessage({ id: 'filecard.clicktoupload' })}
        onFilesSelected={files => uploadFiles(files)}
        uploadProgress={+(progress > 0) && +isUploading && progress}
      />

      <FileContainer>
        {unuploadedFiles.map(attachment => {
          return (
            <FileLink
              key={attachment.name}
              width="45rem"
              text={`${attachment.name}`}
              subtext={intl.formatMessage({ id: 'common.unsaved' })}
              onDelete={() => deleteFile(false, attachment.name)}
            />
          );
        })}
        {uploadedFiles.map(attachment => {
          return (
            <FileLink
              key={attachment.blobName}
              width="45rem"
              text={getFileNameFromBlobUrl(attachment.blobName)}
              onClick={() => downloadAttachment(reactionUid, attachment.uid)}
              onDelete={() => deleteFile(true, attachment.uid)}
            />
          );
        })}
      </FileContainer>
    </>
  );
};

const FileContainer = styled.div`
  margin-top: 2rem;
  > * {
    margin-bottom: 2rem;
  }
`;

export default ReactionFileSection;

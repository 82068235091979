/* eslint-disable @typescript-eslint/indent */
import {
  ReactionEntity,
  ReactionStatus,
  ReactionType
} from '@sdir/httpclient/lib/clients/et/construction';
import { DataCard, TextLink } from '@sdir/sds';
import React, { useContext, useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled, { css, ThemeContext } from 'styled-components';

interface ReactionActionMessageProps {
  reaction: ReactionEntity;
  onClick: (uid: string) => void;
}

const ReactionActionMessage: React.FC<ReactionActionMessageProps> = ({ reaction, onClick }) => {
  const theme = useContext(ThemeContext);
  const intl = useIntl();

  const { reactionStatus, followUpBy, reactionType, numberSeries, uid } = reaction;

  const reactionTypeText = useMemo(() => {
    if (reactionStatus === ReactionStatus.Draft) {
      switch (reactionType) {
        case ReactionType.Comment:
          return intl.formatMessage({ id: 'vessel.activity.reaction.draft.comment' });
        case ReactionType.Mandate:
          return intl.formatMessage({ id: 'vessel.activity.reaction.draft.mandate' });
        default:
          return 'common.draft';
      }
    }

    switch (reactionType) {
      case ReactionType.Comment:
        return intl.formatMessage({ id: 'sendreaction.selectoption.comment' });
      case ReactionType.Mandate:
        return intl.formatMessage({ id: 'sendreaction.selectoption.mandate' });
      default:
        return '';
    }
  }, [reactionStatus, reactionType]);

  const barColor = useMemo(() => {
    switch (reactionStatus) {
      case ReactionStatus.Draft:
        return theme.colors.alert.warningDark;
      case ReactionStatus.Open:
        return theme.colors.alert.successDark;
      default:
        return theme.colors.secondary.grey1;
    }
  }, [reactionStatus]);

  const getText = () => {
    const reactionIsDraft = reactionStatus === ReactionStatus.Draft;

    if (reactionIsDraft) {
      return reactionTypeText;
    }

    return `Venter på ${followUpBy} - ${reactionTypeText}${numberSeries &&
      numberSeries.length &&
      `- ${numberSeries[numberSeries.length - 1]}`}`;
  };

  const openButtonTitle = useMemo(() => {
    if (reactionStatus === ReactionStatus.Draft) {
      return intl.formatMessage({ id: 'constructionnotice.vesselcard.button.label.draft' });
    }

    switch (reactionType) {
      case ReactionType.Comment:
        return intl.formatMessage({ id: 'vesselcard.aggregatedactivitystatus.comments' });
      case ReactionType.Mandate:
        return intl.formatMessage({ id: 'vesselcard.aggregatedactivitystatus.mandate' });
      default:
        return '';
    }
  }, [reactionStatus, reactionType]);

  return (
    <Wrapper>
      <DataCard>
        <DataCardContent>
          <Preview barColor={barColor}>
            <span>{getText()}</span>
          </Preview>
          <TextLink
            text={openButtonTitle}
            onClick={() => uid && onClick(uid)}
            title={openButtonTitle}
          />
        </DataCardContent>
      </DataCard>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 2rem;
  gap: 1.5rem;

  section {
    margin-bottom: 0;
    div {
      line-height: 1;
    }
  }
`;

const Preview = styled.div<{ barColor?: string }>`
  display: flex;
  justify-content: space-between;

  span {
    ${({ barColor }) =>
      barColor &&
      css`
        &::before {
          content: '';
          border-radius: 6px;
          margin-right: 1rem;
          border-left: 4px solid ${barColor};
        }
      `}
  }
`;

const DataCardContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem;
  color: ${({ theme }) => theme.colors.font.text};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 1.75rem;
`;

export default ReactionActionMessage;

import { ProjectActivityV3 } from '@sdir/httpclient/lib/clients/et/construction';
import { tagList } from '@sdir/blueprint.et/lib/helpers';
import { SdiFile } from '@sdir/sds';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';
import useTaskCard from './useTaskCard';
import {
  AccordionWrapper,
  ActivityCardLeftColumn,
  ActivityCardRightColumn,
  TaskCard,
  taskCardAccordion,
  taskMetaCard,
  ToggleState
} from './ActivityCardHelpers';
import CreateReactionModal from '../../Organisms/Reaction/CreateReactionModal';

interface DocumentTaskCardProps {
  activity: ProjectActivityV3;
  isLast: boolean;
  requirementsListTabEnabled: boolean | undefined;
  fetchAndUpdateSubjectAreaActivityCardData: (subjectAreaUri: string | undefined) => void;
  approvalCalBack?: (uri) => void;
}

const DocumentTaskCard: React.FC<DocumentTaskCardProps> = ({
  activity,
  isLast,
  approvalCalBack,
  requirementsListTabEnabled,
  fetchAndUpdateSubjectAreaActivityCardData
}) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { projectuid, surveyuid } = useParams() as { projectuid: string; surveyuid: string };
  // for survey scope page, we are reusing component but log is not required
  const [toggleState, setToggleState] = useState<ToggleState | null>(
    surveyuid ? null : ToggleState.None
  );
  const [viewMetadata, setViewMetadata] = useState<boolean>(false);
  const [viewCreateReactionModal, setViewCreateReactionModal] = useState<boolean>(false);
  const [createReactionUid, setCreateReactionuid] = useState<string | undefined>(undefined);

  const handleToggleState = (state: ToggleState) => {
    if (state === toggleState) setToggleState(ToggleState.None);
    else setToggleState(state);
  };

  const approvalCallback = () => {
    setToggleState(ToggleState.None);
    if (approvalCalBack) approvalCalBack(activity.subjectArea?.value);
  };
  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid } as {
    projectUid: string;
  });

  const {
    sendForApproval,
    approvalLoading,
    getActivityDetails,
    activityDetailsLoading,
    activityDetails,
    numberOfMessages,
    activityMetadata,
    activityMetadataLoading,
    getActivityMetadata,
    activityMeatballMenuItems,
    submitNotRelevant,
    submitRelevant,
    notRelevantLoading,
    relevantLoading,
    getLawText,
    getLawTextAct,
    regulationTextState,
    regulationTextDispatch
  } = useTaskCard({
    projectUid: projectuid,
    activityUid: activity.uid!,
    activityCheckpointUri: activity.checkpointType,
    requirementEliRefs: activity.requirementEliRefs?.[0],
    requirementsListTabEnabled,
    approvalCallback,
    setViewMetadata,
    activity
  });

  const { reactions } = activity;

  const events = activityDetails?.events;

  const handleShowCreateReactionModal = reactionUid => {
    setCreateReactionuid(reactionUid);
    setViewCreateReactionModal(true);
  };

  return (
    <>
      <Wrapper id={activity.uid}>
        <AccordionWrapper width="100%">
          <TaskCard width="100%">
            {ActivityCardLeftColumn(
              intl,
              activity,
              events,
              toggleState,
              getActivityDetails,
              handleToggleState,
              isLast,
              tagList(activity.certificates),
              false
            )}
            {ActivityCardRightColumn(
              intl,
              activity,
              reactions,
              handleShowCreateReactionModal,
              projectuid,
              handleToggleState,
              events,
              undefined,
              undefined,
              activityMeatballMenuItems,
              downloadByFilename,
              <SdiFile />,
              approvalLoading,
              activityDetailsLoading,
              sendForApproval,
              navigate,
              getLawText,
              getLawTextAct,
              regulationTextState,
              regulationTextDispatch
            )}
          </TaskCard>

          {toggleState &&
            taskCardAccordion(
              activityDetails,
              activityDetailsLoading,
              toggleState,
              activity.uid,
              projectuid,
              submitNotRelevant,
              submitRelevant,
              setToggleState,
              notRelevantLoading,
              relevantLoading,
              downloadByFilename,
              numberOfMessages,
              getActivityDetails
            )}
          {activityMetadataLoading}
          {viewMetadata &&
            taskMetaCard(
              activity.uid,
              activityMetadataLoading,
              activityMetadata,
              getActivityMetadata,
              setViewMetadata
            )}
        </AccordionWrapper>
      </Wrapper>
      {viewCreateReactionModal && (
        <CreateReactionModal
          onClose={() => setViewCreateReactionModal(false)}
          fetchAndUpdateSubjectAreaActivityCardData={fetchAndUpdateSubjectAreaActivityCardData}
          activity={activity}
          reactionUid={createReactionUid}
        />
      )}
    </>
  );
};

export default React.memo(DocumentTaskCard);

const Wrapper = styled.div`
  margin-left: 5.5rem;
  width: calc(100% - 5.5rem);
  display: flex;
  justify-content: flex-end;
`;

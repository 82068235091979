import {
  SET_SELECTED_CERTIFICATE_DATA,
  SET_SCOPE_ACTIVITIES_LIST,
  UPDATE_SCOPE_ACTIVITY_RELEVANCE,
  SET_SURVEY_OPTIONS
} from '../constants/action-types';
import { surveyState } from '../constants/types';

const initialSurveyState: surveyState = {
  selectedSurveyCertificateData: {
    $type: '',
    surveyType: {},
    checkpointType: undefined,
    certificateType: undefined,
    inspectionTypes: undefined,
    surveyId: undefined,
    interval: undefined
  },
  surveyOptions: null,
  scopeActivitiesList: [],
  scopeActivitiesListChanged: undefined
};

export default function surveyReducer(state = initialSurveyState, action) {
  switch (action.type) {
    case SET_SELECTED_CERTIFICATE_DATA:
      return {
        ...state,
        selectedSurveyCertificateData: action.payload
      };
    case SET_SURVEY_OPTIONS:
      return {
        ...state,
        surveyOptions: action.payload
      };
    case SET_SCOPE_ACTIVITIES_LIST:
      return { ...state, scopeActivitiesList: action.payload };
    case UPDATE_SCOPE_ACTIVITY_RELEVANCE:
      return { ...state, scopeActivitiesListChanged: action.payload };
    default:
      return state;
  }
}

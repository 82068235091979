import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { checkpointOptionsType } from '@sdir/blueprint.et/lib/components';

const initialState: checkpointOptionsType = {};

const checkpointOptionsSlice = createSlice({
  name: 'checkpointOptions',
  initialState,
  reducers: {
    getCheckpointOptionsAction: (state, action: PayloadAction<checkpointOptionsType>) => {
      return { ...state, ...action.payload };
    }
  }
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { getCheckpointOptionsAction } = checkpointOptionsSlice.actions;
export default checkpointOptionsSlice.reducer;

import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { AxiosError } from 'axios';
import { Loader, DataCard, TextLink, SdiEdit, SdiClock, Textarea } from '@sdir/sds';
import {
  ApplicationSeagoingResult,
  Detail,
  ReportSeafarerSeagoingTimeInDays
} from '@sdir/httpclient/lib/clients/aps/seagoing';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { SeagoingQueryResult } from '@sdir/httpclient/lib/clients/aps/application';
import { SortableDataTable } from '@sdir/utilities/lib/components';
import moment from 'moment';
import { SeagoingVesselPreviewLink } from '@sdir/blueprint';
import { RegisterSeagoingModal } from '@sdir/blueprint.aps/lib/components';
import {
  formatBackendValidationErrors,
  submitSeagoingUpdate
} from '../../helpers/seagoingApiHelpers';
import {
  applicationSeagoingApi,
  seagoingApi,
  seagoingRegistrationApi,
  seagoingTimeCalculatorApi
} from '../../httpclient';
import { AlertMessage } from '../Atoms';
import {
  SeagoingTableHeader,
  SeagoingTableHeaderNew,
  SeagoingTableRow,
  SeagoingTableRowNew
} from '../../helpers/TableHeaders';
import { AlertType } from '../../types/enums';
import { DisplayDateFormat } from '../../helpers';
import ReportingTime, { ReportingTimeData } from './VesselPreviewLink/ReportingTimeModal';
import { DeleteModal } from './DeleteModalLink';
import SeagoingHistoryModal from './SeagoingHistoryModal';

// eslint-disable-next-line max-len
const getSeagoingReportApi = seagoingTimeCalculatorApi?.v1SeagoingtimecalculatorGetreportseafarerseagoingtimeQueryPost.bind(
  seagoingTimeCalculatorApi
);
const getSeagoingHistoryApi = applicationSeagoingApi?.v1ApplicationseagoingGethistoricalapplicationseagoingSeagoingUidGet.bind(
  applicationSeagoingApi
);

const SeagoingTableCardNew: React.FC<{ legalEntityId: string }> = ({ legalEntityId }) => {
  const intl = useIntl();
  const [reportTime, setReportTime] = useState<ReportingTimeData | null>(null);
  const [showReportingModal, setShowReportingModal] = useState(false);
  const [includeExpired, setIncludeExpired] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowUid, setRowUid] = useState('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedSeagoing, setSelectedSeagoing] = useState<SeagoingQueryResult>();
  const [loadingSeagoing, setLoadingSeagoing] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [submitValidationErrors, setSubmitValidationErrors] = useState<any>(undefined);
  const [submitErrors, setSubmitErrors] = useState<any>(undefined);
  const [getErrors, setGetErrors] = useState<any>(undefined);

  const { result: formdataResult, loading: formdataLoading } = useApi({
    apiFunction: () => seagoingRegistrationApi.v2SeagoingregistrationGetvalidformdatalanguageGet(),
    callApiOnInit: true
  });

  const OnCancelModal = useCallback(() => {
    setShowReportingModal(false);
    setReportTime(null);
  }, []);

  const showModal = useCallback(
    (seagoing: ReportSeafarerSeagoingTimeInDays, detail: Detail) => {
      setShowReportingModal(true);
      setReportTime({ ...detail, legalEntityId, serviceCode: seagoing.serviceCode });
    },
    [legalEntityId]
  );

  const showModalDelete = useCallback(uid => {
    setRowUid(uid);
    setShowDeleteModal(true);
  }, []);

  const onCancelDeleteModal = useCallback(() => {
    setShowDeleteModal(false);
  }, []);

  const { result, loading, error, callApi } = useApi({
    apiFunction: getSeagoingReportApi,
    callApiOnInit: false
  });

  const {
    result: resultHistory,
    loading: loadingHistory,
    error: errorHistory,
    callApi: getHistory
  } = useApi({
    apiFunction: uid => getSeagoingHistoryApi(uid),
    callApiOnInit: false
  });

  const onSuccessDeleteSeagoing = useCallback(() => {
    setShowDeleteModal(false);
    callApi({ legalEntityId });
  }, [callApi, legalEntityId]);

  const showModalHistory = useCallback(uid => {
    getHistory(uid);
    setShowHistory(true);
  }, []);

  const showModalEdit = useCallback(row => {
    if (row?.uid) {
      setShowEditModal(true);
      setLoadingSeagoing(true);
      applicationSeagoingApi
        .v1ApplicationseagoingGetapplicationseagoingSeagoingUidGet(row.uid)
        .then(res => res.data)
        .then((seagoing: ApplicationSeagoingResult) => {
          const seagoingFormatted: SeagoingQueryResult = {
            applicationGroupUid: seagoing.applicationGroupUid ?? undefined,
            applicationReference: seagoing.applicationReference ?? undefined,
            callSign: seagoing.callSign,
            dataAccumulatedAt: seagoing.dataAccumulatedAt,
            imoNumber: seagoing.imoNumber,
            personNumber: seagoing.personNumber,
            processingState: seagoing.processingState,
            serviceCode: seagoing.serviceCode,
            signOffDate: seagoing.signOffDate,
            signOnDate: seagoing.signOnDate,
            totalServiceHours: seagoing.totalServiceHours,
            uid: seagoing.uid,
            vesselGrossTonnage: seagoing.vesselGrossTonnage,
            vesselId: seagoing.vesselId,
            vesselLengthOverall: seagoing.vesselLengthOverall,
            vesselLengthOverallUnit: seagoing.vesselLengthOverallUnit,
            vesselName: seagoing.vesselName,
            vesselPropulsionPower: seagoing.vesselPropulsionPower,
            vesselPropulsionType: seagoing.vesselPropulsionType,
            vesselTradeArea: seagoing.vesselTradeArea,
            vesselType: seagoing.vesselType
          };
          setSelectedSeagoing(seagoingFormatted);
          setGetErrors(undefined);
        })
        .catch((seagoingError: AxiosError) => {
          setGetErrors(seagoingError);
        })
        .finally(() => {
          setLoadingSeagoing(false);
        });
    }
  }, []);

  const onCancelEditModal = useCallback(() => {
    setShowEditModal(false);
    setSelectedSeagoing(undefined);
    setSubmitValidationErrors(undefined);
    setSubmitErrors(undefined);
    setGetErrors(undefined);
    setLoadingSubmit(false);
    setComment('');
  }, []);

  const toggleExpired = useCallback(() => setIncludeExpired(!includeExpired), [includeExpired]);

  const onSubmit = async (values: any) => {
    if (!comment || comment.length === 0) {
      setSubmitValidationErrors({
        comment: intl.formatMessage({ id: 'vessel.general.form.error' })
      });
      return;
    }
    setSubmitValidationErrors(undefined);
    setSubmitErrors(undefined);
    setLoadingSubmit(true);
    if (selectedSeagoing?.uid) {
      await submitSeagoingUpdate(values, comment, selectedSeagoing)
        .then(() => {
          onCancelEditModal();
          callApi({ legalEntityId });
        })
        .catch((seagoingError: any) => {
          if (seagoingError?.response?.status === 400) {
            // Handle backend validation errors
            const errors = seagoingError?.response?.data?.errors || {};
            const formattedErrors = formatBackendValidationErrors(errors);
            setSubmitValidationErrors(formattedErrors);
          } else {
            setSubmitErrors(seagoingError);
          }
          setLoadingSubmit(false);
        });
    }
  };

  const onDelete = async () => {
    setLoadingDelete(true);
    try {
      if (selectedSeagoing?.applicationReference) {
        await seagoingApi.v1SeagoingDeleteservicereportforapplicationCommandDelete({
          serviceReportUId: selectedSeagoing?.uid || '',
          applicationUid: selectedSeagoing?.applicationReference || ''
        });
      } else {
        await applicationSeagoingApi.v1ApplicationseagoingDeleteservicereportCommandDelete({
          serviceReportUId: selectedSeagoing?.uid || ''
        });
      }
      onCancelEditModal();
      callApi({ legalEntityId });
    } catch (seagoingError) {
      setSubmitErrors(seagoingError);
      setShowEditModal(true);
    } finally {
      setLoadingDelete(false);
    }
  };

  const tableData = useMemo(() => {
    if (!result) return [];

    return result.map<SeagoingTableRow>(val => {
      const childTableData =
        val.details
          ?.sort((a: Detail, b: Detail) => (b.signOnDate || '').localeCompare(a.signOnDate || ''))
          ?.map<SeagoingTableRowNew>(detail => {
            return {
              vessel: detail?.vessel ? (
                <SeagoingVesselPreviewLink
                  vessel={detail.vessel}
                  accumulatedData={detail.accumulatedData || null}
                />
              ) : (
                ''
              ),
              vesselName: detail?.vessel?.name || '',
              period:
                detail?.signOnDate && detail?.signOffDate
                  ? `${DisplayDateFormat(detail.signOnDate)} - ${DisplayDateFormat(
                      detail.signOffDate
                    )}`
                  : '-',
              periodStartValue: detail?.signOnDate ? moment(detail.signOnDate) : null,
              days: intl.formatNumber(detail.seagoingDays || 0, { maximumFractionDigits: 2 }),
              daysValue: detail.seagoingDays,
              actions: (
                <SeagoingActions>
                  <TextLink
                    data-testid="seagoing-delete-button"
                    icon={<SdiEdit size="s" />}
                    text=""
                    click={() => showModalEdit(detail)}
                  />
                  <TextLink
                    data-testid="seagoing-history-button"
                    icon={<SdiClock size="s" />}
                    text=""
                    click={() => showModalHistory(detail?.uid)}
                  />
                </SeagoingActions>
              )
            };
          }) || [];
      return {
        jobTitle: val.serviceDescription,
        days: intl.formatNumber(val.seagoingDays || 0, { maximumFractionDigits: 2 }),
        daysValue: val.seagoingDays,
        childContent: (
          <SortableDataTable
            columns={SeagoingTableHeaderNew}
            data={childTableData}
            sortableKeys={['jobTitle', 'vessel', 'period', 'days']}
          />
        )
      };
    });
  }, [intl, result, showModal, showModalDelete]);

  useEffect(() => {
    if (legalEntityId) {
      callApi({
        skip: 0,
        take: 100,
        legalEntityId,
        includeOutdatedReports: includeExpired
      });
    }
  }, [callApi, includeExpired, legalEntityId]);

  return (
    <Container>
      <DataCard
        title={intl.formatMessage({ id: 'datacard.seagoing.title' })}
        expandable
        onExpandClicked={toggleExpired}
        linkText={intl.formatMessage({
          id: includeExpired ? 'datacard.seagoing.hideexpired' : 'datacard.seagoing.showexpired'
        })}
      >
        {loading && (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )}
        {error && <AlertMessage message={error} alert={AlertType.Error} />}
        {!loading && !error && tableData?.length > 0 && (
          <SortableDataTable
            columns={SeagoingTableHeader}
            data={tableData}
            collapsable
            collapsableText={[
              intl.formatMessage({ id: 'table.showdetails' }),
              intl.formatMessage({ id: 'table.hidedetails' })
            ]}
            sortableKeys={['jobTitle', 'vessel', 'period', 'days']}
          />
        )}
      </DataCard>
      {showReportingModal && reportTime ? (
        <ReportingTime
          reportingTime={reportTime}
          display={showReportingModal}
          onCancelClick={OnCancelModal}
        />
      ) : null}
      <RegisterSeagoingModal
        application={{}}
        formData={selectedSeagoing ? formdataResult : {}}
        isOpen={showEditModal}
        loading={loadingSeagoing || formdataLoading}
        submitting={loadingSubmit || loadingDelete}
        onClose={onCancelEditModal}
        onSubmit={onSubmit}
        onDelete={onDelete}
        submitErrors={submitErrors}
        getErrors={getErrors}
        submitValidationErrors={submitValidationErrors}
        onSelectAttachment={() => {}}
        hideDelete={false}
        hideAttachmentFields
        seagoing={selectedSeagoing}
        fieldsCanBeDisabled={false}
        extraContentForm={
          <>
            <Textarea
              id="seagoing-comment"
              rows={5}
              onChange={e => setComment(e.target.value)}
              contentValue={comment}
              placeholder={intl.formatMessage({ id: 'vessel.editmodal.comment' })}
              error={submitValidationErrors?.comment || undefined}
            />
          </>
        }
      />
      {showDeleteModal && (
        <DeleteModal
          uid={rowUid}
          display={showDeleteModal}
          onCancelClick={onCancelDeleteModal}
          onSuccessDelete={onSuccessDeleteSeagoing}
        />
      )}
      <SeagoingHistoryModal
        display={showHistory}
        error={errorHistory}
        history={resultHistory}
        loading={loadingHistory}
        onCancelClick={() => setShowHistory(false)}
      />
    </Container>
  );
};
export default SeagoingTableCardNew;

const Container = styled.div`
  position: relative;
`;

const SeagoingActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button:last-child {
    margin-left: 10px;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useMemo } from 'react';
import { Loader, SdirButton } from '@sdir/sds';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useNavigate, useLocation, useParams, generatePath } from 'react-router-dom';
import { ActivitiesSubjectAreasWithActivities } from '@sdir/blueprint.et/lib/components';
import { GetProjectActivitiesV3Result } from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { ProjectActivityV3 } from '@sdir/blueprint.et/lib/types/projectActivityTypes';
import { Routes } from '../Templates/Routes';
import { ToggleContent } from '../Atoms';
import { NewActivityButton } from '../../constants/permissions';
import InspectionTaskCard from '../Molecules/ActivityCard/InspectionTaskCard';
import DocumentControlActivityGroupCard from '../Molecules/ActivityCard/DocumentControlActivityGroupCard';

interface Props {
  loading: boolean;
  removeFilterCallback?: () => void;
  subjectAreasWithTasks?: ActivitiesSubjectAreasWithActivities[];
  height: string;
  currentSelectedSubjectarea?: string;
  requirementsListTabEnabled?: boolean;
  activityList: GetProjectActivitiesV3Result | undefined;
  fetchAndUpdateSubjectAreaActivityCardData: (subjectAreaUri: string | undefined) => void;
}

const ActivityList: React.FC<Props> = ({
  subjectAreasWithTasks,
  loading,
  removeFilterCallback,
  height,
  currentSelectedSubjectarea,
  requirementsListTabEnabled,
  activityList,
  fetchAndUpdateSubjectAreaActivityCardData
}) => {
  const { scrollToId, projectuid } = useParams() as { scrollToId?: string; projectuid?: string };
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  useEffect(() => {
    /* Use optional scroll path param to scroll to activity when loaded */
    if (scrollToId && subjectAreasWithTasks) {
      setTimeout(() => {
        const activityEl = document.getElementById(scrollToId);
        activityEl?.scrollIntoView({ behavior: 'smooth' });
        /* remove scroll path param after */
        const newpathname = location.pathname
          .split('/')
          .slice(0, -1)
          .join('/');
        navigate(newpathname, { replace: true });
      }, 300);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToId, subjectAreasWithTasks]);

  const handleNewActivityClick = () => {
    if (subjectAreasWithTasks?.length) {
      localStorage.setItem(
        'newActivityCurrentSubjectarea',
        currentSelectedSubjectarea ?? subjectAreasWithTasks[0].subjectAreaValue ?? ''
      );
    }
    navigate(
      generatePath(Routes.newactivity, {
        projectuid
      })
    );
  };

  const activitiesForCurrentSubjectArea: ProjectActivityV3[] = useMemo(() => {
    if (!currentSelectedSubjectarea || !subjectAreasWithTasks?.length) {
      return [];
    }

    let activities: ProjectActivityV3[] | null | undefined = [];

    subjectAreasWithTasks?.some(sa => {
      if (sa.subjectAreaValue === currentSelectedSubjectarea) {
        activities = [
          ...(sa.activities ?? []),
          ...(sa.subSubjectAreas ?? []).flatMap(sub => sub.activities ?? [])
        ];
        return true;
      }

      return sa.subSubjectAreas?.some(subsa => {
        if (subsa.value === currentSelectedSubjectarea) {
          activities = subsa.activities;
          return true;
        }
        return false;
      });
    });

    return activities ?? [];
  }, [currentSelectedSubjectarea, subjectAreasWithTasks]);

  const documentsUriInSubjectArea = activities => {
    const uniqueDocumentUris: string[] = [];

    activities.forEach(activity => {
      if (activity.documentUri && !uniqueDocumentUris.includes(activity.documentUri)) {
        uniqueDocumentUris.push(activity.documentUri);
      }
    });

    return uniqueDocumentUris;
  };

  const countNumberOfHiddenActivities = useCallback(
    documentUri => {
      const numberOfUnfilteredActivities = (activityList?.activities ?? [])?.filter(
        act =>
          act.subjectArea?.value === currentSelectedSubjectarea && act.documentUri === documentUri
      ).length;

      const numberOfFilteredActivities = activitiesForCurrentSubjectArea?.filter(
        act => act.documentUri === documentUri
      ).length;

      return numberOfUnfilteredActivities - numberOfFilteredActivities;
    },
    [activityList?.activities, activitiesForCurrentSubjectArea]
  );

  const selectedParentSubjectArea = useMemo(() => {
    return subjectAreasWithTasks?.find(
      subjectArea => subjectArea.subjectAreaValue === currentSelectedSubjectarea
    );
  }, [currentSelectedSubjectarea, subjectAreasWithTasks]);

  return loading ? (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  ) : (
    <>
      <Container height={height}>
        <ToggleContent toggleName={NewActivityButton}>
          <ButtonWrapper>
            <SdirButton
              text={intl.formatMessage({ id: 'documentcard.newactivitybuttonlabel' })}
              onClick={() => handleNewActivityClick()}
            />
          </ButtonWrapper>
        </ToggleContent>
        <Wrapper>
          {selectedParentSubjectArea ? (
            <>
              {selectedParentSubjectArea.activities
                ?.filter(a => !a.documentUri)
                .map(activity => {
                  return (
                    <div key={activity.uid}>
                      <InspectionTaskCard
                        activity={activity as any}
                        requirementsListTabEnabled={requirementsListTabEnabled}
                        fetchAndUpdateSubjectAreaActivityCardData={
                          fetchAndUpdateSubjectAreaActivityCardData
                        }
                      />
                    </div>
                  );
                })}
              {documentsUriInSubjectArea(selectedParentSubjectArea.activities).map(documentUri => {
                const document = activityList?.documents?.find(doc => doc.uri === documentUri);
                if (document) {
                  return (
                    <DocumentControlActivityGroupCard
                      key={documentUri}
                      document={document}
                      activities={(selectedParentSubjectArea.activities ?? []).filter(
                        activity => activity.documentUri === documentUri
                      )}
                      requirementsListTabEnabled={requirementsListTabEnabled}
                      removeFilterCallback={removeFilterCallback}
                      numberOfHiddenActivities={countNumberOfHiddenActivities(documentUri)}
                      fetchAndUpdateSubjectAreaActivityCardData={
                        fetchAndUpdateSubjectAreaActivityCardData
                      }
                    />
                  );
                }
                return null;
              })}
              {selectedParentSubjectArea.subSubjectAreas?.map(subSubjectArea => {
                return (
                  <>
                    {subSubjectArea.activities
                      ?.filter(a => !a.documentUri)
                      .map(activity => {
                        return (
                          <div key={activity.uid}>
                            <InspectionTaskCard
                              activity={activity as any}
                              requirementsListTabEnabled={requirementsListTabEnabled}
                              fetchAndUpdateSubjectAreaActivityCardData={
                                fetchAndUpdateSubjectAreaActivityCardData
                              }
                            />
                          </div>
                        );
                      })}
                    {documentsUriInSubjectArea(subSubjectArea.activities).map(documentUri => {
                      const document = activityList?.documents?.find(
                        doc => doc.uri === documentUri
                      );
                      if (document) {
                        return (
                          <DocumentControlActivityGroupCard
                            key={documentUri}
                            document={document}
                            activities={(subSubjectArea.activities ?? []).filter(
                              activity => activity.documentUri === documentUri
                            )}
                            requirementsListTabEnabled={requirementsListTabEnabled}
                            removeFilterCallback={removeFilterCallback}
                            numberOfHiddenActivities={countNumberOfHiddenActivities(documentUri)}
                            fetchAndUpdateSubjectAreaActivityCardData={
                              fetchAndUpdateSubjectAreaActivityCardData
                            }
                          />
                        );
                      }
                      return null;
                    })}
                  </>
                );
              })}
            </>
          ) : (
            <>
              {activitiesForCurrentSubjectArea
                ?.filter(a => !a.documentUri)
                .map(activity => {
                  return (
                    <div key={activity.uid}>
                      <InspectionTaskCard
                        activity={activity as any}
                        requirementsListTabEnabled={requirementsListTabEnabled}
                        fetchAndUpdateSubjectAreaActivityCardData={
                          fetchAndUpdateSubjectAreaActivityCardData
                        }
                      />
                    </div>
                  );
                })}
              {documentsUriInSubjectArea(activitiesForCurrentSubjectArea).map(documentUri => {
                const document = activityList?.documents?.find(doc => doc.uri === documentUri);
                if (document) {
                  return (
                    <DocumentControlActivityGroupCard
                      key={documentUri}
                      document={document}
                      activities={(activitiesForCurrentSubjectArea ?? []).filter(
                        activity => activity.documentUri === documentUri
                      )}
                      requirementsListTabEnabled={requirementsListTabEnabled}
                      removeFilterCallback={removeFilterCallback}
                      numberOfHiddenActivities={countNumberOfHiddenActivities(documentUri)}
                      fetchAndUpdateSubjectAreaActivityCardData={
                        fetchAndUpdateSubjectAreaActivityCardData
                      }
                    />
                  );
                }
                return null;
              })}
            </>
          )}
        </Wrapper>
      </Container>
    </>
  );
};

const Container = styled.article<{ height: string }>`
  width: 100%;
  margin-bottom: 4rem;
  margin-left: 2rem;
  padding-left: 2rem;

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.primary9};
    outline: ${({ theme }) => theme.colors.primary10};
  }

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
    border-radius: 6px;
    margin: 12px 0;
  }
`;

const LoaderContainer = styled.div`
  display: grid;
  justify-self: center;
  z-index: 1000;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: center;
  grid-column-start: 6;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
`;

export default React.memo(ActivityList);

import React from 'react';
import { SdirBreadcrumb } from '@sdir/sds';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

interface RegulationBreadcrumbProps {
  regulationReferenceId?: string | null | undefined;
  partIndex?: string | null | undefined;
  chapterIndex?: string | null | undefined;
  sectionIndex?: string | null | undefined;
  subsectionIndex?: string | null | undefined;
  handleRegulationClick: () => void;
  handlePartClick: () => void;
  handleChapterClick: () => void;
  handleSectionClick: () => void;
}

interface BreadCrumb {
  text: string;
  path: string;
}

const RegulationBreadcrumb: React.FC<RegulationBreadcrumbProps> = ({
  regulationReferenceId,
  partIndex,
  chapterIndex,
  sectionIndex,
  subsectionIndex,
  handleRegulationClick,
  handlePartClick,
  handleChapterClick,
  handleSectionClick
}) => {
  const intl = useIntl();

  const navigateBreadCrumb = ({ path }) => {
    switch (path) {
      case 'regulation':
        handleRegulationClick();
        break;
      case 'part':
        handlePartClick();
        break;
      case 'chapter':
        handleChapterClick();
        break;
      case 'section':
        handleSectionClick();
        break;
      default:
        break;
    }
  };

  const breadCrumbs = useMemo(() => {
    const crumbs: BreadCrumb[] = [];

    if (regulationReferenceId) {
      crumbs.push({ text: regulationReferenceId, path: 'regulation' });
    }
    if (partIndex) {
      crumbs.push({
        text: `${intl.formatMessage({
          id: 'regulationsselector.breadcrumb.part'
        })} ${partIndex}`,
        path: 'part'
      });
    }
    if (chapterIndex) {
      crumbs.push({
        text: `${intl.formatMessage({
          id: 'regulationsselector.breadcrumb.chapter'
        })} ${chapterIndex}`,
        path: 'chapter'
      });
    }
    if (sectionIndex) {
      crumbs.push({ text: `§ ${sectionIndex}`, path: 'section' });
    }
    if (subsectionIndex) {
      crumbs.push({ text: `(${subsectionIndex})`, path: 'subsection' });
    }

    return crumbs;
  }, [regulationReferenceId, partIndex, chapterIndex, sectionIndex, subsectionIndex]);

  return (
    <BreadCrumbWrapper>
      <SdirBreadcrumb
        separator=">"
        onBreadClicked={crumb => navigateBreadCrumb(crumb)}
        breads={breadCrumbs}
        showHomeIcon={false}
      />
    </BreadCrumbWrapper>
  );
};

const BreadCrumbWrapper = styled.div`
  margin-bottom: 1rem;
  button {
    padding: 0;
  }
`;

export default RegulationBreadcrumb;

import {
  RegulationDto,
  RegulationResult,
  SubsectionDto
} from '@sdir/httpclient/lib/clients/et/regulationtext';
import { useApi } from '@sdir/httpclient/lib/hooks/useApi';
import { AlertShort, Loader, SdirButton, SearchBar } from '@sdir/sds';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import RequirementView from '@sdir/blueprint.et/lib/components/Molecules/RequirementView';
import {
  RegulationReducerType,
  useRegulationText
} from '@sdir/blueprint.et/lib/services/contexts/useRegulationText';
import { getReferanceIdFromUri } from '@sdir/blueprint.et/lib/components/Molecules/ActivityCard/ActivityCard';
import { RequirementDto } from '@sdir/httpclient/lib/clients/et/activity';
import { getAllRegulationsApi, getRegulationLawTextApi } from '../../../httpclient';
import RegulationsList, { scrollTo } from './RegulationsList';
import RegulationBreadcrumb from './RegulationBreadcrumb';

export interface AddedRequirement {
  uri: string;
  version?: number | undefined | null;
}

interface IRegulationSelector {
  addRequirements: (requirement: string[]) => void;
  removeRequirement: (requirement: string) => void;
  addedRequirements: AddedRequirement[];
}

const RegulationsSelector: React.FC<IRegulationSelector> = ({
  addRequirements,
  removeRequirement,
  addedRequirements
}) => {
  const [searchWord, setSearchWord] = useState<string>();
  const [lawText, setLawText] = useState<RegulationDto | undefined>();

  const { state: regulationTextState, dispatch } = useRegulationText();

  const fetchMissingRegulationLawTexts = (
    requirements: RequirementDto[],
    regulationsLawTexts,
    regulationsLoading,
    fetchDataCallback
  ) => {
    const listWithoutDuplicates: RequirementDto[] = requirements.filter(
      (item, index) =>
        requirements.findIndex(
          item2 => item.uri === item2.uri && item.version === item2.version
        ) === index
    );

    listWithoutDuplicates.forEach(requirement => {
      const refId = getReferanceIdFromUri(requirement.uri);

      const allreadyFetchedData = regulationsLawTexts.some(
        lawT => getReferanceIdFromUri(lawT.url) === refId
      );
      const allreadyFetchingData = regulationsLoading.some(reg => reg === refId);

      if (!allreadyFetchedData && !allreadyFetchingData) {
        fetchDataCallback(refId, requirement.version, requirement.uri?.includes('act'));
      }
    });
  };

  const fetchLawTextCallback = (refId, version, isAct) => {
    dispatch({
      type: RegulationReducerType.ADDREGULATIONSLOADING,
      uri: `${refId}version${version}`
    });
    if (isAct) {
      getLawTextAct(refId);
    } else {
      getLawText(refId);
    }
  };

  useEffect(() => {
    if (addedRequirements.length > 0) {
      fetchMissingRegulationLawTexts(
        addedRequirements as RegulationDto[],
        regulationTextState.regulationsData,
        regulationTextState.regulationsLoading,
        fetchLawTextCallback
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedRequirements]);

  const [selectedRegulation, setSelectedRegulation] = useState<number>();
  const [selectedPart, setSelectedPart] = useState<number>();
  const [selectedChapter, setSelectedChapter] = useState<number>();
  const [selectedSection, setSelectedSection] = useState<number>();
  const [selectedSubsection, setSelectedSubsection] = useState<SubsectionDto>();

  const intl = useIntl();

  const handleSearch = (text: string) => {
    setSelectedRegulation(undefined);
    setSelectedPart(undefined);
    setSelectedChapter(undefined);
    setSelectedSection(undefined);
    setSelectedSubsection(undefined);
    setContent(undefined);
    setSearchWord(text);
  };

  /* Keep the last reference ID here in case we need to refetch law text */
  const referenceIdRef = useRef<any>();
  const [content, setContent] = useState<React.ReactNode[]>();
  const [error, setError] = useState<string | undefined>();

  const { result: regulations, loading, error: regulationsError, callApi: refetch } = useApi({
    apiFunction: () => getAllRegulationsApi.v1GetallregulationsGetregulationsv1Get(),
    callApiOnInit: true
  });

  useEffect(() => {
    if (regulationsError) {
      setError(regulationsError);
      setSelectedRegulation(undefined);
      setLawText(undefined);
      setContent(undefined);
    }
  }, [regulationsError]);

  const { callApi: getLawText, loading: lawTextLoading, error: lawTextError } = useApi({
    apiFunction: referenceId => {
      referenceIdRef.current = referenceId;
      const [year, month, day, regulationNumber] = referenceId.split('/');
      return getRegulationLawTextApi.regulationGetregulationlawtextGetlawtextregulationRegulationVersionGet(
        year,
        month,
        day,
        regulationNumber
      );
    },
    successCallback: res => {
      dispatch({
        type: RegulationReducerType.REMOVEREGULATIONSLOADING,
        uri: getReferanceIdFromUri(res.url)
      });
      dispatch({
        type: RegulationReducerType.ADDREGULATIONSDATA,
        lawText: res
      });
      setLawText(res);
    }
  });

  const { callApi: getLawTextAct, loading: lawTextActLoading, error: lawTextActError } = useApi({
    apiFunction: referenceId => {
      referenceIdRef.current = referenceId;
      const [year, month, day, regulationNumber] = referenceId.split('/');
      return getRegulationLawTextApi.actGetregulationlawtextGetlawtextregulationRegulationVersionGet(
        year,
        month,
        day,
        regulationNumber
      );
    },
    successCallback: res => {
      dispatch({
        type: RegulationReducerType.REMOVEREGULATIONSLOADING,
        uri: getReferanceIdFromUri(res.url)
      });
      dispatch({
        type: RegulationReducerType.ADDREGULATIONSDATA,
        lawText: res
      });
      setLawText(res);
    }
  });

  useEffect(() => {
    if (lawTextActError) {
      setError(lawTextActError);
      setSelectedRegulation(undefined);
      setLawText(undefined);
      setContent(undefined);
    }
  }, [lawTextActError]);

  useEffect(() => {
    if (lawTextError) {
      setError(lawTextError);
      setSelectedRegulation(undefined);
      setLawText(undefined);
      setContent(undefined);
    }
  }, [lawTextError]);

  const blurFocusedElement = () => {
    if (document.activeElement) (document.activeElement as HTMLElement).blur();
  };

  const toggleRegulation = async (index: number | undefined, regulation: RegulationResult) => {
    if (index === selectedRegulation) {
      if (
        (selectedPart !== undefined ||
          selectedChapter !== undefined ||
          selectedSection !== undefined ||
          selectedSubsection !== undefined) &&
        lawText
      ) {
        getRegulationText(lawText);
      } else {
        setSelectedRegulation(undefined);
        setContent(undefined);
        blurFocusedElement();
      }
      setSelectedPart(undefined);
      setSelectedChapter(undefined);
      setSelectedSection(undefined);
      setSelectedSubsection(undefined);
    } else {
      const allreadyDownloadedData = regulationTextState.regulationsData.find(
        reg => getReferanceIdFromUri(reg.uid) === getReferanceIdFromUri(regulation.uid)
      );
      if (!allreadyDownloadedData) {
        dispatch({
          type: RegulationReducerType.ADDREGULATIONSLOADING,
          uri: getReferanceIdFromUri(regulation.url)
        });

        if (regulation.url?.includes('act')) {
          getLawTextAct(regulation.referenceId);
        } else {
          getLawText(regulation.referenceId);
        }
      } else {
        setLawText(allreadyDownloadedData);
      }
      setSelectedRegulation(index);
      setSelectedSubsection(undefined);
      setSelectedPart(undefined);
      setSelectedChapter(undefined);
      setSelectedSection(undefined);
    }
  };

  useEffect(() => {
    if (lawText && selectedRegulation !== undefined) {
      getRegulationText(lawText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lawText, selectedRegulation]);

  const togglePart = (index: number | undefined) => {
    if (index === selectedPart) {
      if (
        selectedSubsection !== undefined ||
        selectedSection !== undefined ||
        selectedChapter !== undefined
      ) {
        setSelectedPart(index);
        getPartText(index);
      } else {
        setSelectedPart(undefined);
        lawText && getRegulationText(lawText);
        blurFocusedElement();
      }
      setSelectedChapter(undefined);
      setSelectedSection(undefined);
      setSelectedSubsection(undefined);
    } else {
      getPartText(index);
      setSelectedSubsection(undefined);
      setSelectedPart(index);
      setSelectedChapter(undefined);
      setSelectedSection(undefined);
    }
  };

  const toggleChapter = (index: number | undefined) => {
    if (index === selectedChapter) {
      if (selectedSubsection !== undefined || selectedSection !== undefined) {
        setSelectedChapter(index);
        getChapterText(index);
      } else {
        setSelectedChapter(undefined);
        blurFocusedElement();

        if (selectedPart !== undefined) {
          getPartText(selectedPart);
        } else {
          lawText && getRegulationText(lawText);
        }
      }
      setSelectedSection(undefined);
      setSelectedSubsection(undefined);
    } else {
      getChapterText(index);
      setSelectedChapter(index);
      setSelectedSection(undefined);
      setSelectedSubsection(undefined);
    }
  };

  const toggleSection = (index: number | undefined) => {
    if (index === selectedSection) {
      if (selectedSubsection !== undefined) {
        setSelectedSection(index);
        getSectionText(index);
      } else {
        setSelectedSection(undefined);
        blurFocusedElement();
        if (selectedChapter !== undefined) {
          getChapterText(selectedChapter);
        } else if (selectedPart !== undefined) {
          getPartText(selectedPart);
        } else {
          lawText && getRegulationText(lawText);
        }
      }
      setSelectedSubsection(undefined);
    } else {
      setSelectedSubsection(undefined);
      getSectionText(index);
      setSelectedSection(index);
    }
  };

  const toggleSubSection = (subsection: SubsectionDto) => {
    if (selectedSubsection && subsection.uid === selectedSubsection.uid) {
      setSelectedSubsection(undefined);
      getSectionText(selectedSection);
      blurFocusedElement();

      if (selectedSection !== undefined) {
        getSectionText(selectedSection);
      } else if (selectedChapter !== undefined) {
        getChapterText(selectedChapter);
      } else if (selectedPart !== undefined) {
        getPartText(selectedPart);
      } else {
        lawText && getRegulationText(lawText);
      }
    } else {
      setSelectedSubsection(subsection);
      getSubsectionText(subsection);
    }
  };

  const chapterText = chapter =>
    chapter &&
    `${intl.formatMessage({ id: 'regulationsselector.breadcrumb.chapter' })} ${chapter.index}. ${
      chapter.title
    } ${chapter.sectionFrom &&
      chapter.sectionTo &&
      `(§§ ${chapter.sectionFrom} - ${chapter.sectionTo})`}`;

  const partText = part =>
    part &&
    `${intl.formatMessage({ id: 'regulationsselector.breadcrumb.part' })} ${part.index}. ${
      part.title
    } ${part.sectionFrom && part.sectionTo && `(§§ ${part.sectionFrom} - ${part.sectionTo})`}`;

  const sectionText = section => section && `§ ${section.index}. ${section.title}`;

  const subsectionText = subsection => {
    if (!subsection) return '';

    const text = `${subsection.index && `${subsection.index}. `}${subsection.content}`;

    if (subsection.lists.length) {
      return (
        <>
          <span dangerouslySetInnerHTML={{ __html: text }} />
          <UnorderedList>
            {subsection.lists.map(item => (
              <li key={item.index}>
                <span>{`${item.index}. `} </span>
                <span dangerouslySetInnerHTML={{ __html: item.content }} />
              </li>
            ))}
          </UnorderedList>
        </>
      );
    }

    return <p dangerouslySetInnerHTML={{ __html: text }} />;
  };

  const hasMoreThanOnePart = lawText && lawText.parts && lawText.parts.length > 1;

  const hasMoreThanOneChapter =
    lawText?.parts &&
    lawText.parts[selectedPart || 0].chapters &&
    (lawText.parts[selectedPart || 0].chapters || []).length > 1;

  const getRegulationText = (lawT: RegulationDto) => {
    const text: React.ReactNode[] = [];

    text.push(<h2 key={lawT?.uid}>{lawT?.title}</h2>);

    lawT?.parts?.forEach((part, i) => {
      if (hasMoreThanOnePart) {
        text.push(<h3 key={`${part}${i}`}>{partText(part)}</h3>);
      }

      part.chapters?.forEach(chapter => {
        if (hasMoreThanOneChapter) {
          text.push(<h4 key={chapter.uid}>{chapterText(chapter)}</h4>);
        }
        chapter?.sections?.forEach(section => {
          text.push(<h5 key={section.uid}>{sectionText(section)}</h5>);
          section?.subsections?.forEach(subsection => {
            text.push(<div key={subsection.uid}>{subsectionText(subsection)}</div>);
          });
        });
      });
    });

    setContent(text);
  };

  const getPartText = selected => {
    const text: React.ReactNode[] = [];

    if (selected !== undefined) {
      text.push(
        <h3 key={lawText?.parts?.[selected].uid}>{partText(lawText?.parts?.[selected])}</h3>
      );
      lawText &&
        lawText.parts &&
        lawText.parts[selected].chapters?.forEach(chapter => {
          if (hasMoreThanOneChapter) {
            text.push(<h4 key={chapter.uid}>{chapterText(chapter)}</h4>);
          }
          chapter?.sections?.forEach(section => {
            text.push(<h5 key={section.uid}>{sectionText(section)}</h5>);
            section?.subsections?.forEach(subsection => {
              text.push(<div key={subsection.uid}>{subsectionText(subsection)}</div>);
            });
          });
        });
    }

    setContent(text);
  };

  const getChapterText = selected => {
    const text: React.ReactNode[] = [];

    if (selected !== undefined) {
      text.push(
        <h4 key={lawText?.parts?.[selectedPart || 0].chapters?.[selected].uid}>
          {chapterText(lawText?.parts?.[selectedPart || 0].chapters?.[selected])}
        </h4>
      );
      lawText &&
        lawText.parts &&
        lawText.parts[selectedPart || 0].chapters?.[selected].sections?.forEach(section => {
          text.push(<h5 key={section.uid}>{sectionText(section)}</h5>);
          section?.subsections?.forEach(subsection => {
            text.push(<div key={subsection.uid}>{subsectionText(subsection)}</div>);
          });
        });
    }

    setContent(text);
  };

  const getSectionText = selected => {
    const text: React.ReactNode[] = [];
    if (selected !== undefined) {
      text.push(
        <h5 key={selected}>
          {lawText &&
            sectionText(
              lawText.parts?.[selectedPart || 0].chapters?.[selectedChapter || 0].sections?.[
                selected
              ]
            )}
        </h5>
      );

      lawText &&
        lawText.parts &&
        lawText.parts[selectedPart || 0].chapters?.[selectedChapter || 0].sections?.[
          selected
        ].subsections?.forEach(subsection => {
          text.push(<div key={subsection.uid}>{subsectionText(subsection)}</div>);
        });
    }

    setContent(text);
  };

  const getSubsectionText = (subsection: SubsectionDto) => {
    const text: React.ReactNode[] = [];
    text.push(<div key={subsection.uid}>{subsectionText(subsection)}</div>);

    setContent(text);
  };

  const removeFirstPartOfUri = uri => {
    const actString = 'act/';
    const regulationString = 'regulation/';
    const containtsActPath = uri.includes(actString);
    const containtsRegulationPath = uri.includes(regulationString);

    if (containtsActPath) {
      return `${actString}${uri.split(actString)[1]}`;
    }

    if (containtsRegulationPath) {
      return `${regulationString}${uri.split(regulationString)[1]}`;
    }

    return uri;
  };

  const handleAddRequirement = () => {
    if (selectedSubsection) {
      const uri = selectedSubsection.url;

      const found = addedRequirements.find(el => el.uri === uri);
      if (found) return;

      uri && addRequirements([removeFirstPartOfUri(uri)]);

      return;
    }

    if (selectedSection !== undefined) {
      const subsections =
        lawText?.parts?.[selectedPart || 0].chapters?.[selectedChapter || 0].sections?.[
          selectedSection
        ].subsections;

      const addedRequirementsUri = addedRequirements.map(added => added.uri);

      const subsectionsToBeAdded = subsections?.map(subsection => subsection.url || '');

      const newSubsectionsToBeAdded =
        subsectionsToBeAdded
          ?.map(sub => removeFirstPartOfUri(sub))
          .filter(subsection => !addedRequirementsUri.includes(subsection)) || [];

      addRequirements(newSubsectionsToBeAdded);
    }
  };

  const handleRemoveRequirement = requirementUri => {
    removeRequirement(removeFirstPartOfUri(requirementUri));
  };

  return (
    <Container>
      <RegulationsList
        selectedRegulation={selectedRegulation}
        selectedPart={selectedPart}
        selectedChapter={selectedChapter}
        selectedSection={selectedSection}
        handleRegulationClick={(index, regulation) => {
          toggleRegulation(index, regulation);
        }}
        handlePartClick={index => {
          togglePart(index);
        }}
        handleChapterClick={index => {
          toggleChapter(index);
        }}
        handleSectionClick={index => {
          toggleSection(index);
        }}
        handleSubsectionClick={subsection => {
          toggleSubSection(subsection);
        }}
        lawText={lawText}
        lawTextLoading={lawTextLoading || lawTextActLoading}
        regulations={regulations}
        hasMoreThanOneChapter={hasMoreThanOneChapter}
        hasMoreThanOnePart={hasMoreThanOnePart}
        searchWord={searchWord}
      >
        <SearchbarContainer>
          <SearchBar
            emptySearchEnabled
            value={searchWord}
            placeholder="Søkeord"
            handleBlur={e => handleSearch(e.target.value)}
            searchCallback={val => {
              handleSearch(val);
              return Promise.resolve();
            }}
          />
        </SearchbarContainer>
        {loading && (
          <LoaderContainer data-testid="loader">
            <Loader />
          </LoaderContainer>
        )}
        {error && (
          <LoaderContainer>
            <AlertShort text={error} type="error" />
            <SdirButton
              style={{ marginTop: '2rem' }}
              type="primary"
              text={intl.formatMessage({ id: 'common.retry' })}
              onClick={() => {
                setError(undefined);
                refetch();
              }}
            />
          </LoaderContainer>
        )}
      </RegulationsList>
      <RightContainer>
        {!lawTextLoading && !lawTextActLoading && lawText && (
          <RegulationBreadcrumb
            regulationReferenceId={
              selectedRegulation !== undefined ? lawText?.referenceId : undefined
            }
            partIndex={selectedPart !== undefined ? lawText.parts?.[selectedPart].index : undefined}
            chapterIndex={
              selectedChapter !== undefined
                ? lawText?.parts?.[selectedPart || 0].chapters?.[selectedChapter].index
                : undefined
            }
            sectionIndex={
              selectedSection !== undefined
                ? lawText.parts?.[selectedPart || 0].chapters?.[selectedChapter || 0].sections?.[
                    selectedSection
                  ].index
                : undefined
            }
            subsectionIndex={
              selectedSubsection !== undefined ? selectedSubsection?.index : undefined
            }
            handleRegulationClick={() => {
              setSelectedSubsection(undefined);
              setSelectedPart(undefined);
              setSelectedChapter(undefined);
              setSelectedSection(undefined);
              getRegulationText(lawText);
              setTimeout(() => {
                scrollTo(lawText.referenceId);
              }, 200);
            }}
            handlePartClick={() => {
              togglePart(selectedPart);
            }}
            handleChapterClick={() => {
              toggleChapter(selectedChapter);
            }}
            handleSectionClick={() => {
              toggleSection(selectedSection);
            }}
          />
        )}
        <RegulationContent>
          {lawTextLoading || lawTextActLoading ? (
            <LoadingWrapper>
              <Loader />
            </LoadingWrapper>
          ) : (
            content
          )}
        </RegulationContent>

        {(selectedSubsection !== undefined || selectedSection !== undefined) &&
          !lawTextLoading &&
          !lawTextActLoading && (
            <AddRequirementButton
              htmlType="button"
              onClick={handleAddRequirement}
              disabled={loading}
            >
              {intl.formatMessage({
                id:
                  selectedSubsection !== undefined
                    ? 'activityadmin.new.addrequirement'
                    : 'activityadmin.new.addrequirement.all'
              })}
            </AddRequirementButton>
          )}
        <RequirementView
          onDelete={uri => handleRemoveRequirement(uri)}
          defaultToggled
          uriList={addedRequirements.map(req => req.uri)}
          regulationsLawTexts={regulationTextState.regulationsData}
          regulationsLoading={regulationTextState.regulationsLoading}
        />
      </RightContainer>
    </Container>
  );
};

const LoadingWrapper = styled.div`
  margin-left: 5rem;
`;

const AddRequirementButton = styled(SdirButton)`
  width: fit-content;
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  overflow-y: auto;
  max-height: 62vh;
  min-width: 500px;
`;

const RegulationContent = styled.div`
  margin-bottom: 2rem;

  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    color: ${({ theme }) => theme.colors.font.text};
    font-family: ${({ theme }) => theme.fonts.stfMedium};
    font-size: 2rem;
  }

  h2,
  h3 {
    padding-top: 0 !important;
  }

  p {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    color: ${({ theme }) => theme.colors.font.text};
    font-size: 2rem;
  }
`;

const Container = styled.div`
  display: flex;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 3rem;
`;

const SearchbarContainer = styled.div`
  margin-left: 3rem;
  margin-bottom: 0;
  height: 6rem;
  input {
    margin-bottom: 0;
  }
`;

const UnorderedList = styled.ul`
  margin-top: 0;
  list-style-type: none;
`;

export default RegulationsSelector;

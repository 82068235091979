import React, { useEffect, useState } from 'react';
import {
  AlertSingleLine,
  DataCard,
  Loader,
  SdiFile,
  SdirButton,
  SdirCheckbox,
  SdirDatePicker,
  SdirModal,
  SdirRadioButtonGroup,
  SdiTrash,
  Select,
  styles,
  Textarea,
  TextLink,
  Tooltip
} from '@sdir/sds';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import { ActivityCard } from '@sdir/blueprint.et/lib/components';
import { InspectionIcon } from '@sdir/blueprint.et/lib/components/Atoms/Icons';
import { useSelector } from 'react-redux';
import {
  FollowUpBy,
  ProjectActivityV3,
  ReactionType
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import { Criticality } from '@sdir/httpclient/lib/clients/et/activity';
import { ReactionDraftDto } from '@sdir/httpclient/lib/clients/et/construction';
import { generatePath, useParams } from 'react-router-dom';
import { Routes } from '../../Templates/Routes';
import { TextAreaTitle } from '../ReplyBox/ReplyToApplication';
import useReaction from '../ReplyBox/useReaction';
import ReactionFileSection from '../ReplyBox/ReactionFileSection';
import Signerbox from '../../Molecules/Signerbox';
import RegulationsSelector from '../Requirements/RegulationsSelector';
import AddSignerCaseWorkerModal from '../../Molecules/ActivityDetails/AddSignerCaseWorkerModal';
import useETCaseworker from '../../../services/hooks/useETCaseworker';

interface Props {
  onClose: () => void;
  activity: ProjectActivityV3;
  reactionUid: string | null | undefined;
  fetchAndUpdateSubjectAreaActivityCardData: (subjectAreaUri: string | undefined) => void;
}

const CreateReactionModal: React.FC<Props> = ({
  onClose,
  activity,
  reactionUid,
  fetchAndUpdateSubjectAreaActivityCardData
}) => {
  const fm = useIntl().formatMessage;
  const { projectuid } = useParams() as {
    projectuid: string;
  };

  const [viewAddRequirementModal, setViewAddRequirementModal] = useState(false);
  const [isCaseWorkerModalVisible, setCaseWorkerModalVisible] = useState(false);

  const etCaseWorkers = useSelector((state: any) => state.appState.etCaseWorkers);
  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseWorkersLoading);

  const { getEtCaseWorkers } = useETCaseworker();

  useEffect(() => {
    if (!etCaseWorkers && !etCaseWorkersLoading) {
      getEtCaseWorkers();
    }
  }, []);

  const criticalityOptions = [
    {
      label: fm({
        id: 'activityadmin.task.criticality.beforedeparture'
      }),
      value: Criticality.BeforeDeparture
    },
    {
      label: fm({
        id: 'activityadmin.task.criticality.beforeoperation'
      }),
      value: Criticality.BeforeOperation
    },
    {
      label: fm({
        id: 'activityadmin.task.criticality.beforedeadline'
      }),
      value: Criticality.BeforeDeadline
    }
  ];

  const getActionOptions = () => {
    const options: any = [];

    options.push({
      name: fm({ id: 'sendreaction.selectoption.comment' }),
      value: ReactionType.Comment
    });

    options.push({
      name: fm({ id: 'sendreaction.selectoption.mandate' }),
      value: ReactionType.Mandate
    });

    return options;
  };

  const getFollowUpByOptions = () => {
    const options: any = [];

    options.push({
      name: fm({ id: 'sendreaction.selectoption.sdir' }),
      value: FollowUpBy.Sdir
    });

    options.push({
      name: fm({ id: 'sendreaction.selectoption.customer' }),
      value: FollowUpBy.Customer
    });

    return options;
  };

  const initialCriticalityValue = () => {
    // TODO: First set reactionDetails criticallity
    return activityDetails?.criticality ?? undefined;
  };

  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.profile.sub;
  const currentUserName = currentUser?.profile.name;
  const actionOptions = getActionOptions();
  const followUpByOptions = getFollowUpByOptions();

  const fetchSubjectAreaUriActivities = () => {
    fetchAndUpdateSubjectAreaActivityCardData(activity.subjectArea?.value ?? undefined);
  };

  const {
    loading,
    deleteDraftReaction,
    uploadedAttachments,
    handleReplaceReaction,
    submitDraftReaction,
    updateDraftReactionAndUploadAttachments,
    createDraftReactionAndUploadAttachments,
    storeFilesForUpload,
    newAttachments,
    isUploading,
    progress,
    deleteFile,
    downloadAttachment,
    reactionDetails,
    reactionDetailsLoading,
    activityDetails,
    activityDetailsLoading,
    allUsers
  } = useReaction(activity.uid ?? '', reactionUid, onClose, fetchSubjectAreaUriActivities);

  const validationSchema = Yup.object({
    action: Yup.string().required(fm({ id: 'common.requiredfield' })),
    followUpBy: Yup.string().required(fm({ id: 'common.requiredfield' })),
    requirements: Yup.array()
      .min(1, fm({ id: 'createreaction.error.requirements' }))
      .required(fm({ id: 'createreaction.error.requirements' })),
    deadline: Yup.date()
      .nullable()
      .optional()
      .when(['action', 'criticality'], {
        is: (action: ReactionType, criticality: string) => {
          return action === ReactionType.Mandate && criticality === Criticality.BeforeDeadline;
        },
        then: Yup.date()
          .nullable()
          .required(fm({ id: 'common.requiredfield' }))
          .min(new Date(), fm({ id: 'createreaction.error.futuredate' }))
      }),
    reply: Yup.string()
      .transform((v, o) => (o === '' ? null : v))
      .required(fm({ id: 'common.requiredfield' })),
    criticality: Yup.string().when('action', {
      is: ReactionType.Mandate,
      then: Yup.string().required(fm({ id: 'common.requiredfield' }))
    })
  });

  const formik = useFormik({
    initialValues: {
      action: reactionDetails?.type || ReactionType.Comment.toString(),
      reply: reactionDetails?.text || '',
      deadline: reactionDetails?.deadline ? new Date(reactionDetails?.deadline) : null,
      criticality: initialCriticalityValue(),
      followUpBy: reactionDetails?.followUpBy ?? FollowUpBy.Sdir,
      caseWorker: reactionDetails?.caseWorker || { name: currentUserName, uid: currentUserId },
      requirements: [] as string[]
    },
    validationSchema,
    onSubmit: values => {
      const payload: ReactionDraftDto = {
        text: values.reply,
        reactionType: values.action as ReactionType,
        criticality: values.criticality as Criticality,
        followUpBy: values.followUpBy as FollowUpBy,
        caseWorker: values.caseWorker.uid,
        deadline: values.deadline ? moment(values.deadline).format('YYYY-MM-DD') : null,
        attachments: uploadedAttachments.map(u => u.uid ?? ''),
        requirements: values.requirements.map(r => ({ uri: r, version: 0 }))
      };
      if (reactionDetails?.isSubmitted) {
        handleReplaceReaction(payload, reactionUid);
      } else {
        submitDraftReaction(payload);
      }
    }
  });

  const replyType = actionOptions.find(option => option.value === formik.values.action)?.name || '';

  const handleCreateReaction = () => {
    formik.handleSubmit();
  };

  const handleDraftButton = () => {
    const payload: ReactionDraftDto = {
      text: formik.values.reply,
      reactionType: formik.values.action as ReactionType,
      criticality: formik.values.criticality as Criticality,
      followUpBy: formik.values.followUpBy as FollowUpBy,
      caseWorker: formik.values.caseWorker.uid,
      deadline: formik.values.deadline ? moment(formik.values.deadline).format('YYYY-MM-DD') : null,
      attachments: uploadedAttachments.map(u => u.uid ?? '')
    };

    if (reactionDetails) {
      updateDraftReactionAndUploadAttachments(payload);
    } else {
      createDraftReactionAndUploadAttachments(payload);
    }
  };

  const handleViewRequirement = async eliref => {
    if (eliref) {
      localStorage.setItem('ELIREF_REDIRECT', eliref);
      window.open(
        `${window.location.origin}/#${generatePath(Routes.requirementDetail, {
          projectuid,
          activityuid: activity.uid
        })}`,
        '_blank'
      );
    }
  };

  const getSubmitButtonProps = () => {
    return {
      text: fm({ id: 'createreaction.button.publish.label' }),
      disabled: loading,
      onClick: () => {
        if (validationSchema.isValidSync(formik.values)) {
          handleCreateReaction();
        } else {
          formik.setFieldTouched('action');
          formik.setFieldTouched('reply');
          formik.setFieldTouched('followUpBy');
          formik.setFieldTouched('deadline');
          formik.setFieldTouched('requirements');
        }
      }
    };
  };

  const handleCriticalityChange = val => {
    if (val !== Criticality.BeforeDeadline && formik.values.deadline) {
      formik.setFieldValue('deadline', null);
    }
    formik.setFieldValue('criticality', val);
  };

  const handleReactionTypeChange = val => {
    formik.setFieldValue('action', val);
    if (val !== ReactionType.Mandate) {
      formik.setFieldValue('criticality', undefined);

      if (formik.values.deadline) {
        formik.setFieldValue('deadline', null);
      }
    }

    if (val === ReactionType.Mandate && !formik.values.criticality) {
      formik.setFieldValue('criticality', Criticality.BeforeDeparture);
    }
  };

  const handleOpenAddRequirementModal = () => {
    setViewAddRequirementModal(true);
  };

  const getTitle = () => {
    if (reactionDetails) {
      if (reactionDetails.isSubmitted) {
        return fm({ id: 'vessel.activity.status.replacereaction' });
      }
      return fm({ id: 'vessel.activity.status.updatedraft' });
    }
    return fm({ id: 'vessel.activity.status.newreaction' });
  };

  const handleRequirementChecboxClicked = eliref => {
    const existingValues = formik.values.requirements;
    if (existingValues.includes(eliref)) {
      formik.setFieldValue(
        'requirements',
        existingValues.filter(req => req !== eliref)
      );
    } else {
      formik.setFieldValue('requirements', [...existingValues, eliref]);
    }
  };

  return (
    <>
      <StyledModal
        visible={!viewAddRequirementModal && !isCaseWorkerModalVisible}
        onCancel={onClose}
      >
        {reactionDetailsLoading || activityDetailsLoading ? (
          <Loader />
        ) : (
          <Container>
            <Header>{getTitle()}</Header>
            {reactionDetails?.isSubmitted && (
              <ReplaceReactionNotice>
                <AlertSingleLine
                  title=""
                  type="info"
                  text={fm({ id: 'sendreaction.replacereaction.notice' })}
                  hideClose
                />
              </ReplaceReactionNotice>
            )}
            <ActivityCard
              internalComment={activity?.internalComment}
              title={activity?.name}
              description={activity?.description}
              type={
                activity?.checkpointType &&
                fm({
                  id: `checkpointtype.${activity?.checkpointType?.split('#')[1]?.toLowerCase()}`
                })
              }
              icon={
                activity?.checkpointType?.includes('DocumentControl') ? (
                  <SdiFile />
                ) : (
                  <InspectionIcon />
                )
              }
            />
            <RequirementPicker>
              {(activity?.requirementEliRefs ?? []).map(eliRef => (
                <DataCard key={eliRef}>
                  <EliRefCheckboxWrapper>
                    <ElirefWrapper>
                      <span>{eliRef}</span>
                      <TextLink
                        title={fm({ id: 'sendreaction.textlink.viewrequirement' })}
                        text=""
                        icon={<LinkIcon />}
                        onClick={() => handleViewRequirement(eliRef)}
                      />
                    </ElirefWrapper>
                    <SdirCheckbox
                      checked={formik.values.requirements.includes(eliRef)}
                      onClick={() => handleRequirementChecboxClicked(eliRef)}
                    />
                  </EliRefCheckboxWrapper>
                </DataCard>
              ))}
              {formik.values.requirements
                .filter(req => !(activity?.requirementEliRefs ?? []).includes(req))
                .map(eliRef => (
                  <DataCard key={eliRef}>
                    <EliRefCheckboxWrapper>
                      <ElirefWrapper>
                        <span>{eliRef}</span>
                        <TextLink
                          title={fm({ id: 'sendreaction.textlink.viewrequirement' })}
                          text=""
                          icon={<LinkIcon />}
                          onClick={() => window.open(eliRef, '_blank')}
                        />
                      </ElirefWrapper>
                      <StyledTextLink
                        icon={<SdiTrash />}
                        text=""
                        onClick={() => handleRequirementChecboxClicked(eliRef)}
                      />
                    </EliRefCheckboxWrapper>
                  </DataCard>
                ))}
              <SdirButton
                onClick={handleOpenAddRequirementModal}
                text={fm({ id: 'createreaction.button.addrequirements.label' })}
              />
            </RequirementPicker>
            {formik.touched.requirements && formik.errors.requirements && (
              <ErrorLabel mt="-4rem" mb="4rem" ml="1rem">
                {formik.errors.requirements}
              </ErrorLabel>
            )}
            <ColumnContainer>
              <ColumnItem>
                <Select
                  defaultVal={fm({ id: 'sendreaction.select.type.placeholder' })}
                  options={actionOptions}
                  handleChange={handleReactionTypeChange}
                  value={formik.values.action}
                  error={formik.touched.action ? formik.errors.action : ''}
                />
              </ColumnItem>
              <ColumnItem>
                <TooltipWrapper>
                  <Label>{fm({ id: 'activityadmin.task.criticality' })}</Label>
                  <Tooltip small>{fm({ id: 'createreaction.tooltip.criticality' })}</Tooltip>
                </TooltipWrapper>
                <RadioButtonContainer>
                  <SdirRadioButtonGroup
                    disabled={formik.values.action !== ReactionType.Mandate}
                    options={criticalityOptions}
                    name="criticality"
                    value={formik.values.criticality ?? ''}
                    onChange={e => {
                      handleCriticalityChange(e.target.value);
                    }}
                  />
                </RadioButtonContainer>
                {formik.touched.criticality && formik.errors.criticality && (
                  <ErrorLabel mt="-4rem" mb="4rem" ml="1rem">
                    {formik.errors.criticality}
                  </ErrorLabel>
                )}
              </ColumnItem>
              <ColumnItem>
                <TooltipWrapper>
                  <Select
                    size="300px"
                    defaultVal={fm({
                      id: 'sendreaction.select.followupby.placeholder'
                    })}
                    options={followUpByOptions}
                    handleChange={val => {
                      formik.setFieldValue('followUpBy', val);
                      formik.setFieldTouched('followUpBy');
                    }}
                    value={formik.values.followUpBy}
                    error={formik.touched.followUpBy ? formik.errors.followUpBy : ''}
                  />
                  <Tooltip small>{fm({ id: 'createreaction.tooltip.followupby' })}</Tooltip>
                </TooltipWrapper>
              </ColumnItem>
              <ColumnItem>
                <SdirDatePicker
                  disabled={
                    formik.values.action !== ReactionType.Mandate ||
                    formik.values.criticality !== Criticality.BeforeDeadline
                  }
                  locale="no"
                  id="deadline"
                  placeholder={fm({
                    id: 'sendreaction.deadlineforresponese.header'
                  })}
                  dateFormat="dd.MM.yyyy"
                  onChange={v => {
                    formik.setFieldTouched('deadline', true, true);
                    formik.setFieldValue('deadline', v, true);
                  }}
                  value={formik.values.deadline}
                  error={formik.touched.deadline ? formik.errors.deadline : ''}
                />
              </ColumnItem>
            </ColumnContainer>
            <TextAreaWrapper>
              <TooltipWrapper>
                <TextAreaTitle>{`${replyType} ${fm({
                  id: 'sendreaction.textarea.label'
                })}`}</TextAreaTitle>
                <Tooltip small>
                  {fm({ id: 'createreaction.tooltip.text.header' })}
                  <ul>
                    <li>{fm({ id: 'createreaction.tooltip.text.firstitem' })}</li>
                    <li>{fm({ id: 'createreaction.tooltip.text.seconditem' })}</li>
                  </ul>
                </Tooltip>
              </TooltipWrapper>
              <Textarea
                id="reply"
                contentValue={formik.values.reply}
                onChange={e => {
                  formik.setFieldTouched('reply', true, true);
                  formik.setFieldValue('reply', e.target.value);
                }}
                error={formik.touched.reply ? formik.errors.reply : ''}
                rows={8}
              />
            </TextAreaWrapper>
            <div>
              <ReactionFileSection
                uploadFiles={storeFilesForUpload}
                uploadedFiles={uploadedAttachments}
                unuploadedFiles={newAttachments}
                isUploading={isUploading}
                progress={progress}
                deleteFile={deleteFile}
                reactionUid={reactionDetails?.reactionUid}
                downloadAttachment={downloadAttachment}
              />
            </div>
            <Signerbox
              isOptional
              caseWorker={formik.values.caseWorker}
              setCaseWorkerModalVisible={setCaseWorkerModalVisible}
              loading={loading}
              width="100%"
              hideSigner
            />
            <ButtonContainer
              justifyContent={
                reactionDetails && !reactionDetails.isSubmitted ? 'space-between' : 'flex-end'
              }
            >
              {reactionDetails && !reactionDetails.isSubmitted && (
                <TextLink
                  icon={<SdiTrash size="s" />}
                  text={fm({ id: 'createreaction.button.deletedraft.label' })}
                  click={() => deleteDraftReaction()}
                />
              )}
              {reactionDetails?.isSubmitted && (
                <TextLink text={fm({ id: 'common.cancel' })} click={onClose} />
              )}
              <div>
                {!reactionDetails?.isSubmitted && (
                  <Button
                    type="default"
                    disabled={loading}
                    text={fm({ id: 'createreaction.button.savedraft.label' })}
                    onClick={handleDraftButton}
                  />
                )}
                <Button type="primary" {...getSubmitButtonProps()} />
              </div>
            </ButtonContainer>
          </Container>
        )}
      </StyledModal>
      <StyledModal
        visible={viewAddRequirementModal}
        onCancel={() => setViewAddRequirementModal(false)}
      >
        <RegulationsSelector
          addedRequirements={formik.values.requirements?.map(req => ({ uri: req }))}
          addRequirements={value => {
            const requirementsSet = new Set([...formik.values.requirements, ...value]);
            formik.setFieldValue('requirements', Array.from(requirementsSet));
          }}
          removeRequirement={value =>
            formik.setFieldValue(
              'requirements',
              formik.values.requirements.filter(req => req !== value)
            )
          }
        />
        <ButtonContainer justifyContent="flex-end">
          <SdirButton
            text={fm({ id: 'createreaction.button.backtoreaction.label' })}
            onClick={() => setViewAddRequirementModal(false)}
          />
        </ButtonContainer>
      </StyledModal>
      {isCaseWorkerModalVisible && (
        <AddSignerCaseWorkerModal
          visible
          setVisible={() => setCaseWorkerModalVisible(!isCaseWorkerModalVisible)}
          allUsers={allUsers}
          chosenUser={{
            username: formik.values.caseWorker.name || '',
            signersId: formik.values.caseWorker.uid || ''
          }}
          handleChangeUser={caseWorker => {
            formik.setFieldValue('caseWorker', {
              name: caseWorker.username,
              uid: caseWorker.signersId
            });
            setCaseWorkerModalVisible(!isCaseWorkerModalVisible);
          }}
        />
      )}
    </>
  );
};

const StyledModal = styled(SdirModal)`
  font-size: 16px;
  max-height: 90vh;
  overflow-y: scroll;
`;

export const ErrorLabel = styled.label<{
  mt?: string;
  mb?: string;
  ml?: string;
}>`
  display: block;
  color: ${({ theme }) => theme.colors.alert.errorDark};
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 1.75rem;

  ${({ mt }) => mt && `margin-top: ${mt}`};
  ${({ ml }) => ml && `margin-left: ${ml}`};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
`;

const RequirementPicker = styled.div`
  margin: 2rem 0;

  section {
    margin-bottom: 2rem;
    background-color: ${styles.colors.background.light};
  }
`;

const ButtonContainer = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`;

const Button = styled(SdirButton)`
  margin-left: 5rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  max-width: 1100px;
`;

const ColumnItem = styled.div`
  min-width: 344px;
`;

const ReplaceReactionNotice = styled.div`
  margin-bottom: 4rem;
`;

const EliRefCheckboxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
`;

const Header = styled.h2`
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  font-size: 3rem;
  margin: 0;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 32px;
`;

const ElirefWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.font.text}CC`};
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 2rem;
`;

const TextAreaWrapper = styled.div`
  textarea {
    width: 110rem;
    resize: vertical;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  width: 100%;

  h2 {
    margin-top: 0;
  }
`;

export const RadioButtonContainer = styled.div`
  [data-id='base-item'] {
    margin-right: 4rem;
  }
`;

const StyledTextLink = styled(TextLink)`
  svg {
    height: 24px;
    width: 24px;
  }
`;

export const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" height="16" width="20" viewBox="0 0 640 512">
    <path d="M579.8 267.7c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114L422.3 334.8c-31.5 31.5-82.5 31.5-114 0c-27.9-27.9-31.5-71.8-8.6-103.8l1.1-1.6c10.3-14.4 6.9-34.4-7.4-44.6s-34.4-6.9-44.6 7.4l-1.1 1.6C206.5 251.2 213 330 263 380c56.5 56.5 148 56.5 204.5 0L579.8 267.7zM60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5L217.7 177.2c31.5-31.5 82.5-31.5 114 0c27.9 27.9 31.5 71.8 8.6 103.9l-1.1 1.6c-10.3 14.4-6.9 34.4 7.4 44.6s34.4 6.9 44.6-7.4l1.1-1.6C433.5 260.8 427 182 377 132c-56.5-56.5-148-56.5-204.5 0L60.2 244.3z" />
  </svg>
);

export default CreateReactionModal;

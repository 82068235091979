import React from 'react';
import { isMobile } from 'react-device-detect';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { styles } from '@sdir/sds';
import { SdirAuthProvider } from '@sdir/auth/lib/providers';
import { SdirAuthProviderProps } from '@sdir/auth/lib/providers/SdirAuthProvider';
import { Provider } from 'react-redux';
import { ToastProvider } from '@sdir/utilities/lib/providers';
import { RegulationTextProvider } from '@sdir/blueprint.et/lib/services/contexts/useRegulationText';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import { persistStore } from 'redux-persist';
import store from '../../../store';

import messages from '../../../localization/localization';
import IsMobile from '../../../services/contexts/ResponsiveContext';
import { FeatureToggleProvider } from './FeatureToggleProvider/FeatureToggleProvider';

interface InputProps {
  children?: any;
}

const Providers: React.FC<InputProps> = ({ children }) => {
  const persistor = persistStore(store);
  const authSettings: SdirAuthProviderProps = {
    authenticationSettings: {
      client_id: process.env.REACT_APP_CLIENT_ID as string,
      client_secret: process.env.REACT_APP_CLIENT_SECRET as string,
      scope: process.env.REACT_APP_SCOPE as string
    }
  };

  const lang = window.location.pathname?.split('/')?.includes('en') ? 'en' : 'nb';
  return (
    <SdirAuthProvider {...authSettings}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <FeatureToggleProvider>
            <IntlProvider locale={lang} messages={messages[lang]}>
              <ThemeProvider theme={styles}>
                <ToastProvider>
                  <RegulationTextProvider>
                    <IsMobile.Provider value={isMobile}>{children}</IsMobile.Provider>
                  </RegulationTextProvider>
                </ToastProvider>
              </ThemeProvider>
            </IntlProvider>
          </FeatureToggleProvider>
        </PersistGate>
      </Provider>
    </SdirAuthProvider>
  );
};

export default Providers;

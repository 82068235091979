import React from 'react';
import { ChapterDto, SubsectionDto } from '@sdir/httpclient/lib/clients/et/regulationtext';
import { SdiArrow, styles } from '@sdir/sds';
import { useIntl } from 'react-intl';
import { Button, ChevronWrapper, ListItem, TitleWrapper, scrollTo } from './RegulationsList';
import { Section } from './Section';

export interface IChapter {
  selectedChapter: number | undefined;
  selectedSection: number | undefined;
  chapterIndex: number;
  handleChapterClick: (index: number | undefined) => void;
  handleSectionClick: (index: number | undefined) => void;
  chapter: ChapterDto;
  handleSubsectionClick: (subsection: SubsectionDto) => void;
}
export const Chapter: React.FC<IChapter> = ({
  selectedChapter,
  chapterIndex,
  handleChapterClick,
  handleSectionClick,
  chapter,
  selectedSection,
  handleSubsectionClick
}) => {
  const active = selectedChapter === chapterIndex;
  const intl = useIntl();

  const onChapterClick = () => {
    handleChapterClick(chapterIndex);
    setTimeout(() => {
      scrollTo(chapter.uid);
    }, 200);
  };

  return (
    <ListItem paddingRight="0" id={chapter.uid}>
      <Button type="button" active={active} onClick={onChapterClick}>
        <TitleWrapper>
          {`${intl.formatMessage({
            id: 'regulationsselector.breadcrumb.chapter'
          })} ${chapter.index}. ${chapter.title}`}
          <ChevronWrapper>
            <SdiArrow
              direction={active ? 'up' : 'down'}
              height="1rem"
              size="s"
              color={active ? styles.colors.font.white : styles.colors.primary2}
            />
          </ChevronWrapper>
        </TitleWrapper>
      </Button>
      {active && (
        <ul>
          {chapter.sections?.map((section, sectionIndex) => (
            <Section
              handleSubsectionClick={handleSubsectionClick}
              key={section.uid}
              section={section}
              sectionIndex={sectionIndex}
              selectedSection={selectedSection}
              handleSectionClick={handleSectionClick}
            />
          ))}
        </ul>
      )}
    </ListItem>
  );
};

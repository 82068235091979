import React from 'react';

import styled, { css } from 'styled-components';
import { Loader, SdiArrow, styles } from '@sdir/sds';
import {
  RegulationDto,
  RegulationResult,
  SubsectionDto
} from '@sdir/httpclient/lib/clients/et/regulationtext';
import { Part } from './Part';
import { Chapter } from './Chapter';
import { Section } from './Section';

export const scrollTo = targetId => {
  const targetEl = document.getElementById(targetId);
  setTimeout(() => {
    if (targetEl && targetEl.scrollIntoView) {
      targetEl.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, 10);
};

interface IRegulationsList {
  selectedRegulation: number | undefined;
  selectedPart: number | undefined;
  selectedChapter: number | undefined;
  selectedSection: number | undefined;
  handleRegulationClick: (index: number | undefined, regulation: RegulationResult) => void;
  handlePartClick: (index: number | undefined) => void;
  handleChapterClick: (index: number | undefined) => void;
  handleSectionClick: (index: number | undefined) => void;
  lawText: RegulationDto | undefined;
  regulations: RegulationResult[] | undefined;
  lawTextLoading: boolean;
  hasMoreThanOnePart: boolean | null | undefined;
  hasMoreThanOneChapter: boolean | null | undefined;
  handleSubsectionClick: (subsection: SubsectionDto) => void;
  children?: React.ReactNode | React.ReactNode[];
  searchWord: string | undefined;
}

const RegulationsList: React.FC<IRegulationsList> = ({
  selectedRegulation,
  selectedPart,
  selectedChapter,
  selectedSection,
  handleRegulationClick,
  handlePartClick,
  handleChapterClick,
  handleSectionClick,
  lawText,
  regulations,
  lawTextLoading,
  hasMoreThanOneChapter,
  hasMoreThanOnePart,
  handleSubsectionClick,
  children,
  searchWord
}) => {
  return (
    <ListContainer>
      {children}
      <List>
        {regulations
          ?.filter(
            el =>
              el.title?.toLowerCase().includes(searchWord?.toLowerCase() || '') ||
              el.referenceId?.toLowerCase().includes(searchWord?.toLowerCase() || '')
          )
          .map((regulation, regulationIndex) => (
            <ListItem key={regulation.uid} id={regulation.referenceId || ''} paddingRight="2rem">
              <Button
                type="button"
                active={selectedRegulation === regulationIndex}
                onClick={() => {
                  scrollTo(regulation.referenceId);
                  handleRegulationClick(regulationIndex, regulation);
                }}
              >
                <ReferenceIdTag>{regulation.referenceId}</ReferenceIdTag>
                <TitleWrapper>
                  {regulation.title}
                  <ChevronWrapper>
                    <SdiArrow
                      direction={selectedRegulation === regulationIndex ? 'up' : 'down'}
                      height="1rem"
                      size="s"
                      color={styles.colors.primary2}
                    />
                  </ChevronWrapper>
                </TitleWrapper>
              </Button>
              {selectedRegulation === regulationIndex && (
                <>
                  {lawTextLoading && (
                    <span data-testid="loader">
                      <Loader size="3rem" />
                    </span>
                  )}
                  <ul>
                    {/* This one has multiple parts and chapters */}
                    {lawText &&
                      !lawTextLoading &&
                      hasMoreThanOnePart &&
                      lawText.parts?.map((part, partIndex) => (
                        <Part
                          key={part.uid}
                          part={part}
                          partIndex={partIndex}
                          selectedChapter={selectedChapter}
                          selectedPart={selectedPart}
                          handleChapterClick={handleChapterClick}
                          handlePartClick={handlePartClick}
                          handleSectionClick={handleSectionClick}
                          selectedSection={selectedSection}
                          handleSubsectionClick={handleSubsectionClick}
                        />
                      ))}

                    {/* This one has no parts */}
                    {lawText &&
                      !lawTextLoading &&
                      lawText.parts &&
                      !hasMoreThanOnePart &&
                      hasMoreThanOneChapter &&
                      lawText.parts[0].chapters?.map((chapter, chapterIndex) => (
                        <Chapter
                          key={chapter.uid}
                          chapter={chapter}
                          chapterIndex={chapterIndex}
                          selectedChapter={selectedChapter}
                          handleChapterClick={handleChapterClick}
                          handleSectionClick={handleSectionClick}
                          selectedSection={selectedSection}
                          handleSubsectionClick={handleSubsectionClick}
                        />
                      ))}

                    {/* This one has no parts and no chapters */}
                    {lawText &&
                      lawText.parts &&
                      lawText.parts[0].chapters &&
                      !lawTextLoading &&
                      !hasMoreThanOnePart &&
                      !hasMoreThanOneChapter &&
                      lawText.parts[0].chapters[0].sections?.map((section, sectionIndex) => {
                        return (
                          <Section
                            key={section.uid}
                            section={section}
                            sectionIndex={sectionIndex}
                            selectedSection={selectedSection}
                            handleSectionClick={handleSectionClick}
                            handleSubsectionClick={handleSubsectionClick}
                          />
                        );
                      })}
                  </ul>
                </>
              )}
            </ListItem>
          ))}
      </List>
    </ListContainer>
  );
};

const List = styled.ul`
  height: 89rem;
  overflow-y: scroll;

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.primary9};
    outline: ${({ theme }) => theme.colors.primary10};
  }

  &::-webkit-scrollbar {
    width: 1rem;
    height: 1rem;
  }
`;

export const ChevronWrapper = styled.div`
  margin-left: 1.5rem;
`;

export const ListItem = styled.li<{ paddingRight: string }>`
  list-style-type: none;
  width: 100%;
  padding-right: ${({ paddingRight }) => paddingRight};
`;

export const Button = styled.button<{ active?: boolean }>`
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  cursor: pointer;
  font-size: 2rem;
  border: none;
  outline: none;
  width: 100%;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  margin-bottom: 2.5rem;
  background-color: transparent;

  &:focus,
  &:hover {
    color: ${({ theme }) => theme.colors.font.white};
    border-radius: 0.75rem;
    background: ${({ theme }) => theme.colors.primary};

    svg {
      fill: ${({ theme }) => theme.colors.font.white};
    }
  }

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.font.white};
      border-radius: 0.75rem;
      background: ${({ theme }) => theme.colors.primary};

      svg {
        fill: ${({ theme }) => theme.colors.font.white};
      }
    `}
`;

export const ReferenceIdTag = styled.div`
  background: ${({ theme }) => theme.colors.primaryLightBlue9};
  border-radius: 0.5rem;
  padding: 0.3rem;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.75rem;
  width: fit-content;
  margin-bottom: 1rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  white-space: nowrap;
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  word-break: break-word;
  text-align: left;
  width: 100%;
  align-items: center;
`;

const ListContainer = styled.div`
  min-width: 48rem;
  width: 48rem;
  height: 100rem;
  background-color: ${({ theme }) => theme.colors.primary10};
  border-radius: 6px;
  padding: 3rem 3rem 3rem 0;
  margin-bottom: 4rem;
  overflow: hidden;

  ul {
    padding-left: 3rem;
  }
`;

export default RegulationsList;

/* eslint-disable prettier/prettier */
import React, { useCallback, useEffect, useState } from 'react';
import {
  DataCard,
  Select,
  Textarea,
  SdirButton,
  SdirDatePicker,
  styles,
  TextLink,
  SdiFile
} from '@sdir/sds';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  AddSignerPayload,
  ChangeSignerPayload,
  DocumentControlActivityGroup,
  GetActivityDetailsV2Result,
  SignStatus,
  SubmitForSigningPayload
} from '@sdir/httpclient/lib/clients/et/vesselcaseworker';
import {
  ActivityCard,
  Confirm,
  InformationCard,
  InformationCardPageContainer,
  ToggleButton
} from '@sdir/blueprint.et/lib/components';
import {
  DataLabel,
  DataValue,
  DataWrapper
} from '@sdir/blueprint.et/lib/components/Molecules/InformationCard';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useCurrentUser } from '@sdir/auth/lib/hooks';
import { useSelector } from 'react-redux';
import { InspectionIcon } from '@sdir/blueprint.et/lib/components/Atoms/Icons';
import Signerbox from '../../Molecules/Signerbox';

import { PreviewReplyModal } from '../../Molecules';
import { ActivityDetailType } from '../../../types/enums';
import AddSignerCaseWorkerModal, {
  UserTypes
} from '../../Molecules/ActivityDetails/AddSignerCaseWorkerModal';
import useReply from './useReply';
import ReplyFileSection from './ReplyFileSection';

import { Signer } from '../../../store/constants/types';
import { fileExists } from '../../../helpers/fileHelpers';
import { Routes } from '../../Templates/Routes';
import FileTable, { FiletableBackground } from '../../Molecules/ActivityCard/FileTable';
import { useDownloadByFilename } from '../../../services/hooks/useDownloadByFilename';

interface Props {
  activity: GetActivityDetailsV2Result | undefined;
  vesselName: string;
  children?: React.ReactNode;
  document?: DocumentControlActivityGroup | undefined;
}

enum AnswerOptions {
  ApproveWithCondition = 'approveWithCondition',
  Approve = 'approve',
  Reject = 'reject'
}

const ReplyToApplication: React.FC<Props> = ({ activity, vesselName, children, document }) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isSigneeModalVisible, setSigneeModalVisible] = useState(false);
  const [isReplyPreviewVisible, setReplyPreviewVisible] = useState(false);
  const [isToggled, setIsToggled] = useState(false);

  const { type, projectuid, activityuid: activityUid } = useParams() as {
    type: string;
    projectuid: string;
    activityuid: string;
  };

  const etCaseWorkersLoading = useSelector((state: any) => state.appState.etCaseworkersLoading);

  const currentUser = useCurrentUser();
  const currentUserId = currentUser?.profile.sub;

  const actionUid =
    activity?.actionNotRelevantResult?.actionUid || activity?.actionExemptionResult?.actionUid;

  const checkpointType = activity?.checkpointType?.split('#')[1].toLowerCase();

  const { downloadByFilename } = useDownloadByFilename({ projectUid: projectuid } as {
    projectUid: string;
  });

  const {
    handleAcceptNotRelevant,
    handleRejectNotRelevant,
    loading,
    handleAcceptExemption,
    handleAcceptExemptionWithCondition,
    handleExemptionReject,
    exemptionLoading,
    activityDetailResult,
    getActivityDetails,
    signer,
    sendToSigner,
    setSigner,
    signStatus,
    uploadedFiles,
    uploadFile,
    progress,
    isUploading,
    addSigner,
    addSignerLoading,
    setUploadedFiles,
    allUsers,
    removeSigner,
    changeSigner
  } = useReply(actionUid || '');

  const getHeader = () => {
    switch (type) {
      case ActivityDetailType.exemption:
        return intl.formatMessage({ id: 'replytoexemtion.header' });
      case ActivityDetailType.notrelevant:
        return intl.formatMessage({ id: 'caseworker.reply.notrelevant.title' });
      default:
        return '';
    }
  };

  const onUpload = async (file: File) => {
    const actionMessageBlobNames = getThisMessage()?.reply?.attachments?.map(attachment => ({
      fileName: attachment?.blobName?.split('/')[3].toLowerCase()
    }));

    const fileAlreadyExistsInStorage = fileExists(
      file.name.toLowerCase(),
      actionMessageBlobNames || []
    );

    const fileAlreadyExistsInState = fileExists(file.name, uploadedFiles);

    let confirm = true;
    if (fileAlreadyExistsInStorage || fileAlreadyExistsInState) {
      confirm = await Confirm.show({
        title: intl.formatMessage({ id: 'common.download.areyousure' }),
        text: intl.formatMessage({ id: 'common.download.alreadyexists' }, { fileName: file.name }),
        okText: intl.formatMessage({
          id: 'common.ok'
        }),
        cancelText: intl.formatMessage({
          id: 'common.cancel'
        })
      });
    }

    if (confirm) {
      uploadFile(file);
    }
  };

  const handleAddSigner = async (newSigner: Signer) => {
    const payload: AddSignerPayload = {
      signerUid: newSigner.signersId
    };
    const result = await addSigner(payload);

    if (result.success) {
      setSigneeModalVisible(false);
      setSigner(newSigner);
    }
  };

  const handleChangeSigner = async (newSigner: Signer) => {
    const payload: ChangeSignerPayload = {
      signerUid: newSigner.signersId
    };

    const result = await changeSigner(payload);
    if (result.success) {
      setSigneeModalVisible(false);
      setSigner(newSigner);
    }
  };

  const handleRemoveSigner = async () => {
    if (signer?.signersId) {
      const res = await removeSigner();
      if (res.success) setSigner(undefined);
    }
  };

  const callSigningApi = async () => {
    const payload: SubmitForSigningPayload = {
      reply: formik.values.reply
    };

    await sendToSigner(payload);
    await getActivityDetails();
  };

  const getThisMessage = useCallback(() => {
    if (activityDetailResult) {
      switch (type) {
        case ActivityDetailType.exemption:
          return activityDetailResult.actionExemptionResult;
        case ActivityDetailType.notrelevant:
          return activityDetailResult.actionNotRelevantResult;
        default:
          return undefined;
      }
    }
    return undefined;
  }, [activityDetailResult, type]);

  useEffect(() => {
    // Load reply from caseworker
    if (activityDetailResult) {
      const thisMessage = getThisMessage();

      const reply = thisMessage?.reply?.text;
      if (reply) {
        formik.setFieldValue('reply', reply);
      }

      if (formik.values.reply.length) formik.setFieldTouched('reply');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityDetailResult]);

  const selectOptionsExemption = [
    {
      name: intl.formatMessage({ id: 'replytoapplication.select.answer.accept' }),
      value: AnswerOptions.Approve
    },
    {
      name: intl.formatMessage({ id: 'replytoapplication.select.answer.acceptwithcondition' }),
      value: AnswerOptions.ApproveWithCondition
    },
    {
      name: intl.formatMessage({ id: 'replytoapplication.select.answer.reject' }),
      value: AnswerOptions.Reject
    }
  ];

  const selectOptionsNotRelevant = selectOptionsExemption.filter(
    op => op.value !== AnswerOptions.ApproveWithCondition
  );

  const validationSchema = Yup.object({
    action: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' })),
    reply: Yup.string().required(intl.formatMessage({ id: 'common.requiredfield' })),
    validFrom: Yup.date().optional(),
    validTo: Yup.date().optional()
  });

  const formik = useFormik({
    initialValues: {
      action: '',
      reply: '',
      validFrom: (activity?.actionExemptionResult?.message?.validFrom as any) || '',
      validTo: (activity?.actionExemptionResult?.message?.validTo as any) || ''
    },
    validationSchema,
    onSubmit: values => {
      // make attachment relative and remove token from querysting part and url plus projectid
      const uploadedFilesName = uploadedFiles?.map(fileDetail =>
        fileDetail.blobUrl
          ?.split('/')
          ?.slice(4)
          ?.join('/')
          ?.split('?')
          ?.shift()
      );

      switch (type) {
        case ActivityDetailType.exemption: {
          switch (values.action) {
            case AnswerOptions.Approve: {
              handleAcceptExemption({
                reply: values.reply,
                exemptionId: actionUid,
                validFrom: values.validFrom || undefined,
                validTo: values.validTo || undefined,
                attachments: uploadedFilesName
              });
              break;
            }
            case AnswerOptions.Reject:
              handleExemptionReject({
                reply: values.reply,
                exemptionId: actionUid,
                attachments: uploadedFilesName
              });
              break;
            case AnswerOptions.ApproveWithCondition:
              handleAcceptExemptionWithCondition({
                reply: values.reply,
                exemptionId: actionUid,
                validFrom: values.validFrom || undefined,
                validTo: values.validTo || undefined,
                attachments: uploadedFilesName
              });
              break;
            default:
              break;
          }
          break;
        }
        case ActivityDetailType.notrelevant: {
          switch (values.action) {
            case AnswerOptions.Approve:
              handleAcceptNotRelevant({
                projectUid: projectuid,
                activityUid,
                message: values.reply,
                attachments: uploadedFilesName
              });
              break;
            case AnswerOptions.Reject:
              handleRejectNotRelevant({
                projectUid: projectuid,
                activityUid,
                message: values.reply,
                // remove download token from blobUrl of uploaded files
                attachments: uploadedFilesName
              });
              break;
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  });

  const getSubmitButtonProps = () => {
    if (
      type === ActivityDetailType.notrelevant ||
      (signStatus === SignStatus.Optional && !signer)
    ) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.sendtocustomer' }),
        disabled: loading || exemptionLoading,
        onClick: () => {
          try {
            validationSchema.validateSync(formik.values);
          } catch (exception) {
            if (formik.errors.action) formik.setFieldTouched('action');
            if (formik.errors.reply) formik.setFieldTouched('reply');
          } finally {
            if (!formik.errors.action && !formik.errors.reply) {
              if (type === ActivityDetailType.notrelevant) {
                handleSendToCustomer();
              } else {
                setReplyPreviewVisible(true);
              }
            }
          }
        }
      };
    }

    if (signStatus === SignStatus.Mandatory) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.sendtosigner' }),
        disabled: !signer || loading,
        onClick: () => {
          try {
            validationSchema.validateSync(formik.values);
          } catch (exception) {
            if (formik.errors.action) formik.setFieldTouched('action');
            if (formik.errors.reply) formik.setFieldTouched('reply');
          } finally {
            if (!formik.errors.action && !formik.errors.reply) {
              callSigningApi();
            }
          }
        }
      };
    }

    if (signStatus === SignStatus.Optional && signer) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.sendtosigner' }),
        disabled: loading || exemptionLoading,
        onClick: () => {
          try {
            validationSchema.validateSync(formik.values);
          } catch (exception) {
            if (formik.errors.action) formik.setFieldTouched('action');
            if (formik.errors.reply) formik.setFieldTouched('reply');
          } finally {
            if (!formik.errors.action && !formik.errors.reply) {
              callSigningApi();
            }
          }
        }
      };
    }

    if (signStatus === SignStatus.SubmittedForSigning) {
      return {
        text: intl.formatMessage({ id: 'caseworker.reply.signandsendtocustomer' }),
        disabled: currentUserId !== signer?.signersId,
        onClick: () => {
          try {
            validationSchema.validateSync(formik.values);
          } catch (exception) {
            if (formik.errors.action) formik.setFieldTouched('action');
            if (formik.errors.reply) formik.setFieldTouched('reply');
          } finally {
            if (!formik.errors.action && !formik.errors.reply) setReplyPreviewVisible(true);
          }
        }
      };
    }

    return { text: '...', disabled: true };
  };

  const handleSendToCustomer = () => {
    formik.handleSubmit();
  };

  const handleBackNavigation = () => {
    navigate(
      generatePath(Routes.vesselWorkspaceScrollTo, {
        tab: 'activitylist',
        projectuid,
        scrollToId: activityUid
      })
    );
  };

  const handleViewRequirement = async eliref => {
    if (eliref) {
      localStorage.setItem('ELIREF_REDIRECT', eliref);
      window.open(
        `${window.location.origin}/#${generatePath(Routes.requirementDetail, {
          projectuid,
          activityuid: activityUid
        })}`,
        '_blank'
      );
    }
  };

  const leftContent = (
    <InformationCard borderColor={styles.colors.alert.warningDark} name={vesselName}>
      <DataWrapper>
        <DataLabel>
          {intl.formatMessage({ id: 'replytoapplication.infocard.type.label' })}
        </DataLabel>
        <DataValue>
          {type === ActivityDetailType.exemption
            ? intl.formatMessage({ id: 'replytoapplication.infocard.type.exemption' })
            : intl.formatMessage({ id: 'replytoapplication.infocard.type.notrelevant' })}
        </DataValue>
      </DataWrapper>
    </InformationCard>
  );

  const rightContent = (
    <>
      <DataCard>
        <Container>
          <TopPart>
            <Title>{getHeader()}</Title>
            <CustomerApplicationContainer>
              <ApplicationHeader>{getThisMessage()?.message?.title}</ApplicationHeader>
              <ApplicationText>{getThisMessage()?.message?.text}</ApplicationText>
            </CustomerApplicationContainer>
            <ToggleButtonContainer>
              <ToggleButton
                isToggled={isToggled}
                onClick={() => setIsToggled(!isToggled)}
                text={intl.formatMessage({
                  id: document
                    ? 'replytoapplication.document.toggle.label'
                    : 'replytoapplication.inspection.toggle.label'
                })}
              />
              {isToggled && (
                <ToggleContentWrapper>
                  <ActivityCard
                    internalComment={activity?.internalComment}
                    title={activity?.name}
                    description={activity?.description}
                    type={
                      checkpointType &&
                      intl.formatMessage({
                        id: `checkpointtype.${checkpointType}`
                      })
                    }
                    icon={checkpointType === 'documentcontrol' ? <SdiFile /> : <InspectionIcon />}
                    elirefs={activity?.requirementEliRefs}
                    goToRequirementClick={eliref => handleViewRequirement(eliref)}
                  />
                  {checkpointType === 'documentcontrol' && (
                    <FileUploadWrapper>
                      <HeaderContainer>
                        <Header>
                          <Type>{intl.formatMessage({ id: 'documentcard.document' })}</Type>
                          <Icon>
                            <SdiFile />
                          </Icon>
                          <ActivityTitle>{document?.documentLabel}</ActivityTitle>
                        </Header>
                      </HeaderContainer>
                      <Subtitle>{intl.formatMessage({ id: 'documentcard.documentdesc' })}</Subtitle>
                      <Description>{document?.documentDescription}</Description>
                      <FiletableBackground>
                        {document?.attachments && document.attachments.length > 0 ? (
                          <FileTable
                            files={document.attachments}
                            downloadFile={downloadByFilename}
                          />
                        ) : (
                          <p>{intl.formatMessage({ id: 'documentcard.nouploadedfiles' })}</p>
                        )}
                      </FiletableBackground>
                    </FileUploadWrapper>
                  )}
                </ToggleContentWrapper>
              )}
            </ToggleButtonContainer>
          </TopPart>
          <BottomPart>
            <SelectContainer>
              <Select
                defaultVal={intl.formatMessage({ id: 'caseworker.reply.select' })}
                options={
                  type === ActivityDetailType.notrelevant
                    ? selectOptionsNotRelevant
                    : selectOptionsExemption
                }
                handleChange={val => formik.setFieldValue('action', val)}
                value={formik.values.action}
                error={formik.touched.action ? formik.errors.action : ''}
              />
              {type !== ActivityDetailType.notrelevant && (
                <Signerbox
                  setSigneeModalVisible={setSigneeModalVisible}
                  signer={signer}
                  loading={loading || exemptionLoading || etCaseWorkersLoading}
                  isOptional={signStatus === SignStatus.Optional}
                  caseWorker={undefined}
                />
              )}
            </SelectContainer>

            {activity?.actionExemptionResult?.message?.validFrom &&
            activity?.actionExemptionResult?.message?.validTo ? (
              <>
                <DatePickerContainer>
                  <SdirDatePicker
                    locale="no"
                    dateFormat="dd.MM.yyyy, H:mm"
                    showTimeSelect
                    selectRange
                    minDate={new Date()}
                    startPlaceholder={intl.formatMessage({
                      id: 'dispensationcard.form.datepicker.from'
                    })}
                    endPlaceholder={intl.formatMessage({
                      id: 'dispensationcard.form.datepicker.to'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'dispensationcard.form.datepicker.choosedate'
                    })}
                    startValue={new Date(formik.values.validFrom) as any}
                    endValue={new Date(formik.values.validTo) as any}
                    onStartChange={dateString => {
                      formik.setFieldValue('validFrom', dateString);
                    }}
                    onEndChange={dateString => {
                      formik.setFieldTouched('validFrom');
                      formik.setFieldTouched('validTo');
                      formik.setFieldValue('validTo', dateString);
                    }}
                    error={formik.touched.validTo && (formik.errors.validTo as any)}
                  />
                </DatePickerContainer>
              </>
            ) : (
              ''
            )}
            <TextAreaTitle>{vesselName && `${vesselName} - ${getHeader()}`}</TextAreaTitle>
            <Textarea
              id="reply"
              placeholder={intl.formatMessage({
                id: 'replytoreaction.comment.placeholder'
              })}
              contentValue={formik.values.reply}
              onChange={e => formik.setFieldValue('reply', e.target.value)}
              error={formik.touched.reply ? formik.errors.reply : ''}
              rows={3}
            />
            <UploadInfo>
              {document
                ? `${
                    intl
                      .formatMessage({
                        id: 'replytoapplication.documentcontrol.uploadinfo'
                      })
                      .split('%s')[0]
                  }${document.documentLabel}${
                    intl
                      .formatMessage({
                        id: 'replytoapplication.documentcontrol.uploadinfo'
                      })
                      .split('%s')[1]
                  }`
                : intl.formatMessage({
                    id: 'replytoapplication.inspection.uploadinfo'
                  })}
            </UploadInfo>
            <ReplyFileSection
              actionMessage={getThisMessage()}
              isUploading={isUploading}
              progress={progress}
              uploadFile={onUpload}
              uploadedFiles={uploadedFiles}
              onDeleteSuccess={async () => {
                await getActivityDetails();
                setUploadedFiles([]);
              }}
            />
            <ButtonContainer>
              <TextLink
                style={{ fontSize: '2rem' }}
                text={intl.formatMessage({ id: 'common.cancel' })}
                click={() => handleBackNavigation()}
              />
              <SdirButton type="primary" {...getSubmitButtonProps()} />
            </ButtonContainer>
          </BottomPart>
        </Container>
      </DataCard>
      {children}
    </>
  );

  return (
    <>
      <InformationCardPageContainer leftContent={leftContent} rightContent={rightContent} />
      <AddSignerCaseWorkerModal
        visible={isSigneeModalVisible}
        setVisible={() => setSigneeModalVisible(!isSigneeModalVisible)}
        handleAddUser={handleAddSigner}
        addUserLoading={addSignerLoading}
        allUsers={allUsers}
        handleRemoveUser={handleRemoveSigner}
        chosenUser={signer}
        handleChangeUser={handleChangeSigner}
        userType={UserTypes.Signee}
      />
      <PreviewReplyModal
        visible={isReplyPreviewVisible}
        setVisible={() => setReplyPreviewVisible(!isReplyPreviewVisible)}
        accept={() => handleSendToCustomer()}
        reply={formik.values.reply}
        replyType={getHeader()}
        loading={loading || exemptionLoading}
        vesselName={vesselName}
        signer={signer}
      />
    </>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
`;

export const TextAreaTitle = styled.h2`
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  margin-bottom: 2rem;
`;

const CustomerApplicationContainer = styled.div`
  border-radius: 0.375rem;
  border-left: ${({ theme }) => `1rem solid ${theme.colors.alert.warningDark};`}
  margin: 5rem 0 2rem;
  padding: 3rem
`;

const Title = styled.h1`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  margin: 0;
`;

const UploadInfo = styled.p`
  margin: 2rem 0;
`;

const ApplicationHeader = styled.p`
  margin: 0 0 0.5rem;
  font-size: 1.75rem;
  line-height: 1.75rem;
`;

const ApplicationText = styled.div`
  padding: 1.875rem 1.625rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.primary10};
`;

const Container = styled.div`
  width: 100%;

  textarea {
    width: 108rem;
  }
`;

const TopPart = styled.div`
  padding: 4rem;
`;

const BottomPart = styled.div`
  padding: 4rem;
  background-color: ${({ theme }) => theme.colors.primaryLightBlue10};
`;

const SelectContainer = styled.div`
  width: 52rem;
  margin: 3rem 0 3rem 0;
`;

const DatePickerContainer = styled.div`
  width: 50rem;

  label {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    color: ${({ theme }) => theme.colors.font};
    font-size: 2rem;
  }

  margin: 2rem 0;
`;

const ToggleButtonContainer = styled.span`
  & div {
    font-family: ${({ theme }) => theme.fonts.stfBook};
    font-size: 2rem;
  }
  & button {
    padding-left: 0;
  }
`;

const ToggleContentWrapper = styled.div`
  margin: 2rem 2rem 4rem;
`;

const Header = styled.div`
  display: grid;
  grid-template-areas:
    '. type'
    'icon title';
  justify-content: start;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Type = styled.span`
  grid-area: type;
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 1.75rem;
  && {
    line-height: 1;
  }
`;

const Subtitle = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  font-size: 1.75rem;
  color: ${({ theme }) => theme.colors.secondary.grey1};
  display: block;
  padding-top: 4rem;
`;

const Icon = styled.span`
  grid-area: icon;
  margin-right: 2rem;
  svg {
    fill: ${({ theme }) => theme.colors.font.text};
  }
`;

const ActivityTitle = styled.span`
  grid-area: title;
  font-family: ${({ theme }) => theme.fonts.stfMedium};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 3rem;
`;

const FileUploadWrapper = styled.div`
  border-radius: 6px;
  border: 1px solid #4e7694;
  padding: 3rem;
  margin-top: 2rem;
`;

const Description = styled.span`
  font-family: ${({ theme }) => theme.fonts.stfBook};
  color: ${({ theme }) => theme.colors.font.text};
  font-size: 2rem;
  display: block;
  padding-top: 1rem;
  padding-bottom: 5rem;
`;

export default ReplyToApplication;
